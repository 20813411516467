<template>
  <div class="v-card" v-if="card" @click.stop="showDetail" :class="cardClasses">
    <div class="title-wrapper" v-html="hightlightedTerm(card['Trial Title'])"></div>
    <div class="info-wrapper">
      <!-- <button v-if="overflow" class="close-card" @click.stop="closeCard" name="close-card" aria-label="Close Card Button">
        <img src="../assets/icons/close-grey.svg" width="16" height="16" alt="close button">
      </button> -->
      <div class="study-status" :class="statusClasses(card['Study Recruitment Status'])">{{ card['Study Recruitment Status'] }}</div>
      <div>
        Indikation:
        <label
          v-for="(healthCondition, index) in card.healthConditions"
          :key="`healthCondition-${ index }`"
        >
          <span v-html="hightlightedTerm(healthCondition)"></span>
          <span v-if="index < card.healthConditions.length - 1">, </span>
        </label>
        <span v-if="!card.healthConditions.length">
          -
        </span>
      </div>
      <div>
        Phase:
        <label>
          <span v-if="card['Trial Phase'].length">
            {{ Array.isArray(card['Trial Phase']) ? card['Trial Phase'].join(', ') : card['Trial Phase'] }}
          </span>
          <span v-else>-</span>
        </label>
      </div>
      <div>
        Wirkstoff:
        <label
          v-for="(intervention, index) in card.interventions"
          :key="`intervention-${ index }`"
        >
          <span v-html="hightlightedTerm(intervention['Intervention Name'])"></span>
          <span v-if="index < card.interventions.length - 1">, </span>
        </label>
        <span v-if="!card.interventions.length">
          -
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'v-card',

  props: {
    card: {
      type: Object,
      default: null
    },

    overflow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters([
      'selectedSearchTerms',
      'selectedMapStudies'
    ]),

    cardClasses () {
      return [
        'card',
        {
          overflow: this.overflow
        }
      ]
    }
  },

  methods: {
    ...mapActions([
      'setCurrentStudy'
    ]),

    statusClasses (status) {
      return {
        recruiting: status === 'Recruiting',
        'not-recruiting': status === 'Not Yet Recruiting',
        active: status === 'Active, not recruiting',
        completed: status === 'Completed'
      }
    },

    openModal () {
      this.setCurrentStudy(this.card)
      this.$emit('open-modal')
    },

    showDetail () {
      this.setCurrentStudy(this.card)
      this.$router.push('/card-detail')
    },

    hightlightedTerm (value) {
      let text = value
      this.selectedSearchTerms.forEach(term => {
        const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        text = text.replace(
          new RegExp(escapedTerm, 'gi'),
          '<span class="highlight">$&</span>'
        )
      })
      return text
    },

    closeCard () {
      this.$emit('close-card')
    }
  }
}
</script>

<style lang="stylus">
.card
  display: flex
  flex-direction: row
  justify-content: flex-start
  width: 100%
  min-height: 135px
  background: white
  border-radius: 10px
  margin-bottom: 25px
  box-sizing: border-box
  border-left: 10px solid #595454
  overflow: hidden
  box-shadow: 2px 2px 10px 0px #bfbfbf
  cursor: pointer

  &:hover
    box-shadow: none

  &.overflow
    position: absolute

  .title-wrapper
    padding: 18px 25px 25px 20px
    font-family: "BMS Humanity Bold"
    font-size: 14px
    line-height: 18px
    flex: 1

  .info-wrapper
    display: flex
    flex-direction: column
    position: relative
    width: 315px
    min-width: 315px
    max-width: 315px
    background: #f6f5f4
    padding: 18px 50px 25px 15px
    border-left: 2px solid #e9e8e8
    font-family: "BMS Humanity Regular"
    font-size: 12px

    label
      font-family: "BMS Humanity Bold"

    div
      margin-bottom: 5px

    .close-card
      position: absolute
      display: flex
      justify-content: center
      align-items: center
      width: 49px
      height: 49px
      top: 0
      right: 0
      background: white
      border-radius: 0px 0px 0px 10px
      box-sizing: border-box
      padding: 13px
      border: none

      img
        width: 22px

  .highlight
    background: #ff0
</style>
