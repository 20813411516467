<template>
  <div class="card-detail" :class="{ blur: openPopupCounter > 0 }">
    <div class="info-container-1">
      <div class="info-container-1-wrapper">
        <div class="info-container-1-top-content">
          <div class="info-container-1-left">
            <div class="title-wrapper wrapper">
              <label>Title</label>
              <div>
                {{ currentStudy['Trial Title'] }}
              </div>
            </div>
            <div class="wrapper indication-wrapper">
              <label>Indikation</label>
              <div
                v-for="(healthCondition, index) in currentStudy.healthConditions"
                :key="`healthCondition-${ index }`"
              >
                <span v-html="healthCondition"></span>
              </div>
              <div v-if="!currentStudy.healthConditions.length">
                -
              </div>
            </div>
          </div>
          <div class="info-container-1-right">
            <div class="row-1">
              <div class="wrapper ingredient-wrapper">
                <label>Wirkstoff</label>
                <div v-for="(intervention, index) in currentStudy.interventions"
              :key="`intervention-${ index }`" v-html="intervention['Intervention Name']"></div>
                <div v-if="!currentStudy.interventions.length">
                  -
                </div>
              </div>
              <div class="wrapper studystatus-wrapper">
                <label>Studienstatus</label>
                <div class="study-status" :class="statusClasses(currentStudy['Study Recruitment Status'])">
                  {{ currentStudy['Study Recruitment Status'] }}
                </div>
              </div>
            </div>
            <div class="row-2">
            </div>
            <div class="row-3">
              <div class="wrapper enrollment-wrapper">
                <label>Enrollment Goal</label>
                <div>{{ currentStudy['Enrollment Goal'] }}</div>
              </div>
              <div class="wrapper nct-wrapper">
                <label>NCT ID</label>
                <div>{{ currentStudy['NCT Id Grouping Code'] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper info-overview-wrapper">
          <label>Overview</label>
          <div v-html="currentStudy['Trial Overview']"></div>
        </div>
      </div>
      <div class="qr-code-wrapper">
        <v-qrcode :url="currentStudy.URL" :size="144"></v-qrcode>
        <label class="qr-infotext">
          Für weitere Informationen zu dieser Studie
          scannen Sie den QR Code. Für weitere Informationen
          zur Teilnahme an klinischen Studien von
          Bristol Myers Squibb besuchen Sie bitte die Website
          <a href="https://www.bmsstudyconnect.com/us/en/home.html" target="blank">Find Clinical Trials | Innovative Clinical Research - Study Connect</a>
        </label>
      </div>
    </div>
    <div class="info-container-2">
      <div class="wrapper">
        <label>Startdatum</label>
        <div>
          {{ formatDate(currentStudy['Start Date']) }}
        </div>
      </div>
      <div class="wrapper">
        <label>Primäres Enddatum</label>
        <div>
          {{ formatDate(currentStudy['Primary Completion Date']) }}
        </div>
      </div>
      <div class="wrapper">
        <label>Enddatum der Studie</label>
        <div>
          {{ formatDate(currentStudy['Completion Date']) }}
        </div>
      </div>
      <div class="wrapper">
        <label>Gender</label>
        <div>{{ currentStudy['Gender'] }}</div>
      </div>
      <div class="wrapper">
        <label>Age</label>
        <div>{{ currentStudy['Age Range'] }}</div>
      </div>
      <div class="wrapper">
        <label>Locations</label>
        <div>
          {{ currentStudy.cities.length }}
        </div>
      </div>
      <div class="wrapper">
        <label>Phase</label>
        <div>
          <span v-if="currentStudy['Trial Phase'].length">
            {{ Array.isArray(currentStudy['Trial Phase']) ? currentStudy['Trial Phase'].join(', ') : currentStudy['Trial Phase'] }}
          </span>
          <span v-else>-</span>
        </div>
      </div>
    </div>
    <div class="map-container">
      <v-map
        :study="currentStudy"
        no-modal
      >
      </v-map>
    </div>
    <div class="info-container-3">
      <table>
        <thead>
          <tr>
            <th>Orte</th>
            <th class="spacer"></th>
            <th>Site Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(city, index) in currentStudy.cities" :key="`city-${ index }`">
            <td>{{ city['Site City'] }}</td>
            <td v-if="!currentStudy.cities.length"> - </td>
            <td class="spacer"></td>
            <td>{{ city['Site Name'] || '-' }}</td>
            <td v-if="!currentStudy.cities.length"> - </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import vMap from '../components/v-map.vue'
import vQrcode from '../components/v-qrcode.vue'

export default {
  name: 'v-card-detail',

  components: {
    vQrcode,
    vMap
  },

  data: () => ({
    isOverviewOpen: false
  }),

  computed: {
    ...mapGetters([
      'currentStudy',
      'openPopupCounter'
    ])
  },

  methods: {
    ...mapActions([
      'deleteCurrentStudy'
    ]),

    statusClasses (status) {
      return {
        recruiting: status === 'Recruiting',
        'not-recruiting': status === 'Not Yet Recruiting',
        active: status === 'Active, not recruiting',
        completed: status === 'Completed'
      }
    },

    goBack () {
      this.deleteCurrentStudy()
      this.$router.push('/')
    },

    formatDate (dateString) {
      const date = dayjs(dateString)

      return date.format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="stylus">
.card-detail
  display: flex
  flex-direction: column
  overflow: auto
  margin-top: 30px

  .qr-icon
    display: none !important

  .qr-infotext
    font-size: 12px
    font-family: "BMS Humanity Regular"

    a
      color: #595454

      &:visited
        color: #595454

  .wrapper
    display: grid
    grid-template-rows: 12px 1fr
    gap: 10px
    padding-top: 10px

    label
      font-size: 12px
      font-family: "BMS Humanity Regular"

  .info-container-1
    display: flex
    flex-direction: row
    align-items: flex-start
    padding: 0px 40px 40px 40px
    line-height: 22px

    div
      font-size: 16px
      font-family: "BMS Humanity Bold"

    .info-container-1-wrapper
      display: flex
      flex-direction: column
      width: 100%

      .info-container-1-top-content
        display: flex
        flex-direction: row

        .info-container-1-left
          display: flex
          flex-direction: column
          margin-right: 16px
          width: 100%

          .indication-wrapper
            margin-bottom: 50px
            line-height: 18px
            border-top: 1px solid #eeeded

          .title-wrapper
            border-top: 1px solid #eeeded

        .info-container-1-right
          display: flex
          flex-direction: column
          min-width: 305px
          max-width: 305px
          width: 305px
          margin-right: 5px

          .row-1
            display: flex
            flex-direction: row
            margin-bottom: 10px

            .ingredient-wrapper
              flex: 1
              margin-right: 20px
              line-height: 18px
              border-top: 1px solid #eeeded

            .studystatus-wrapper
              flex: 1
              margin-right: 20px
              border-top: 1px solid #eeeded

              div
                font-size: 12px

          .row-2
            margin-bottom: 10px

            .inclusion-wrapper
              margin-right: 20px
              border-top: 1px solid #eeeded

          .row-3
            display: flex
            flex-direction: row

            .enrollment-wrapper,
            .nct-wrapper
              margin-right: 20px
              flex: 1
              line-height: 18px
              border-top: 1px solid #eeeded

      .info-overview-wrapper
        margin-right: 16px

        div
          width: 100%
          font-size: 14px
          font-family: "BMS Humanity Bold"
          line-height: 22px

    .qr-code-wrapper
      width: 144px

  .info-container-2
    display: flex
    flex-direction: row
    align-items: flex-start
    height: 121px
    padding: 40px 40px 40px 40px
    background: #f7f3f3

    div
      font-size: 20px
      font-family: "BMS Humanity Bold"
      margin-right: 30px

  .map-container
    height: 608px
    background: #c6c6c6
    overflow: hidden

  .info-container-3
    display: flex
    align-items: flex-start
    padding: 40px

    table, th, td
      font-size: 14px
      font-family: "BMS Humanity Bold"
      line-height: 22px

      th
        font-size: 12px
        font-family: "BMS Humanity Regular"
        text-align: left
        padding-bottom: 10px
        line-height: 12px
        padding-top: 4.4px
        white-space: nowrap

        &:not(.spacer)
          border-top: 1px solid #eeeded

      th.spacer, td.spacer
        width: 15px

    table
      margin-right: 15px
      width: 100%

    .wrapper
      border-top: 1px solid #eeeded

      div
        display: inline-block
        font-size: 14px
        font-family: "BMS Humanity Bold"
        line-height: 22px
</style>
