<template>
  <div class="searchbar">
    <label>Finden</label>
    <div class="searchbar-wrapper">
      <input type="text" v-model="value" placeholder="Suchbegriff eingeben" @input="showResults" :class="classes">
      <button class="delete-value" v-if="value" @click="deleteValue" name="delete-value" aria-label="Delete Value Button">
        <img src="../assets/icons/close-icon-grey.svg" width="15.746" height="15.746" alt="close button">
      </button>
      <button v-if="value !== ''" @click="startSearch" name="start-search" aria-label="Start Search Button">
        <img src="../assets/icons/magnify-icon.svg" width="19.811" height="19.811" alt="search button">
      </button>
      <button v-else @click="closeSearchBar" name="close-searchbar">
        <img src="../assets/icons/close-icon.svg" width="13.438" height="13.438" alt="close button" aria-label="Close Button">
      </button>
      <div class="dropdown-results" v-if="searchTerms.length > 0">
        <div
          v-for="(term, index) in searchTerms"
          :key="`term-${index}`"
          v-html="term"
          @click="selectTerm(term)"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'v-searchbar',

  props: {
    searchTerm: {
      type: String,
      default: null
    }
  },

  data: () => ({
    value: '',
    searchTerms: []
  }),

  mounted () {
    this.$root.events.on('uncheck-all-filters', this.deleteValue)
    this.$root.events.on('delete-value', this.deleteValue)
  },

  computed: {
    ...mapGetters([
      'searchTermList'
    ]),

    classes () {
      return {
        typing: this.searchTerms.length > 0
      }
    }
  },

  methods: {
    ...mapActions([
      'setSearchTerm',
      'deleteSearchTerms',
      'deleteSearchTerm'
    ]),

    closeSearchBar () {
      this.$emit('close-searchbar')
    },

    // compare every searchterm with value
    showResults () {
      this.searchTerms = []
      Object.keys(this.searchTermList).forEach(term => {
        if (
          this.value.length >= 2 &
          term.toLowerCase().indexOf(this.value.toLowerCase()) >= 0 &&
          term !== this.value
        ) {
          this.searchTerms.push(term)
        }
      })
    },

    deleteValue () {
      this.value = ''
      this.deleteSearchTerms()
      this.searchTerms = []
    },

    selectTerm (term) {
      this.value = term
      this.showResults()
      this.startSearch()
    },

    startSearch () {
      if (this.value === '26738368') {
        this.$emit('open-popup')
        this.$emit('close-searchbar')
      } else {
        this.setSearchTerm(this.value)
        this.$root.events.emit('use-filters')
        this.$emit('close-searchbar')
      }
    }
  }
}
</script>

<style lang="stylus">
.searchbar
  flex: 1
  margin-right: 10px

  label
    font-size: 12px

  &-wrapper
    display: flex
    flex-direction: row
    position: relative

    input
      flex: 1
      height: 49px
      background: white
      box-sizing: border-box
      padding: 13px
      border: 1px solid #e4dede
      border-right: transparent
      border-radius: 4px 0px 0px 4px
      font-size: 16px
      font-family: "BMS Humanity Regular"
      align-items: center
      color: #595454

      &.typing
        border: transparent
        box-shadow: 0px 11px 16px 0px #bfbfbf
        border-radius: 4px 0px 0px 0px

    .delete-value
      background: white
      border-radius: 0

    button
      width: 49px
      height: 49px
      background: #BE2BBB
      border-radius: 0px 4px 4px 0px
      border: none

    .dropdown-results
      position: absolute
      display: flex
      flex-direction: column
      width: 100%
      height: auto
      top: 48px
      left: 0px
      padding: 20px
      border-radius: 0px 0px 10px 10px
      box-shadow: 0px 16px 21px 0px #bfbfbf
      background: white
      z-index: 2

      div
        width: fit-content
        font-family: "BMS Humanity Regular"
        color: #595454
        font-size: 16px
        background: #F6F3F3
        padding: 10px
        border-radius: 20px
        margin-bottom: 8px
        cursor: pointer

        &:last-of-type
          margin-bottom: 0

</style>
