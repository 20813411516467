<template>
  <div class="generic-filter" :class="classes" :style="styles">
    <div class="generic-filter-wrapper">
      <div class="generic-filter-header">
        <div class="title">Filtern nach {{ title }}</div>
        <div class="button-container">
          <button class="reset-button" @click="resetFilter" name="reset-filter" aria-label="Reset Button">Filter zurücksetzen</button>
          <button class="apply-button" @click="useFilter" name="apply-filter" aria-label="Apply Button">Filter anwenden</button>
        </div>
      </div>
      <div
        class="generic-filter-content"
        v-if="data"
      >
        <div class="generic-filter-content-checkboxes">
          <v-checkbox
            v-for="(key, index) in sortedData"
            :key="`checkbox-${ index }`"
            :title="key"
            :value="key"
            :checked="isChecked(key)"
            :ref="`checkbox-${ index }`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vCheckbox from '../components/v-checkbox.vue'

export default {
  name: 'v-generic-filter',

  components: {
    vCheckbox
  },

  props: {
    data: {
      type: Object,
      default: null
    },

    title: {
      type: String,
      default: null
    },

    open: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    top: 0,
    width: 0
  }),

  computed: {
    ...mapGetters([
      'studies',
      'selectedIndications',
      'selectedIngredients',
      'filters'
    ]),

    sortedData () {
      return Object.keys(this.data).sort()
    },

    styles () {
      return {
        top: `${ this.top }px`,
        width: `${ this.width }px`
      }
    },

    classes () {
      return {
        open: this.open
      }
    }
  },

  mounted () {
    window.addEventListener('resize', () => this.calculatePosition())
    this.calculatePosition()

    setTimeout(() => {
      this.calculatePosition()
    }, 100)

    this.$root.events.on('uncheck-all-filters', this.resetFilter)
    this.$root.events.on('uncheck-indication', this.unselectIndicationCheckboxByValue)
    this.$root.events.on('uncheck-ingredient', this.unselectIngredientCheckboxByValue)
    this.$root.events.on('select-all-filters', this.useFilter)
  },

  methods: {
    ...mapActions([
      'selectIndication',
      'unselectIndication',
      'unselectAllIndications',
      'selectIngredient',
      'unselectIngredient',
      'unselectAllIngredients'
    ]),

    getValue (value) {
      return this.deepFilter ? value[this.deepFilter] : value
    },

    isChecked (value) {
      if (this.title === 'Indikation') {
        return this.selectedIndications.includes(value)
      } else if (this.title === 'Wirkstoff') {
        return this.selectedIngredients.includes(value)
      }

      return false
    },

    calculatePosition () {
      const toolbar = document.querySelector('.toolbar')
      const cardOverview = document.querySelector('.card-overview')

      if (toolbar && cardOverview) {
        const toolbarRect = toolbar.getBoundingClientRect()
        const cardOverviewRect = cardOverview.getBoundingClientRect()
        this.top = toolbarRect.top + toolbarRect.height
        this.width = cardOverviewRect.width
      }
    },

    unselectCheckboxByValue (value) {
      Object.keys(this.data).forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-${ index }`]
        if (checkbox && checkbox.isActive && checkbox.value === value) {
          checkbox.uncheck()
        }
      })
    },

    unselectIndicationCheckboxByValue (value) {
      if (this.title === 'Indikation') {
        this.unselectCheckboxByValue(value)
      }
    },

    unselectIngredientCheckboxByValue (value) {
      if (this.title === 'Wirkstoff') {
        this.unselectCheckboxByValue(value)
      }
    },

    resetFilter () {
      Object.keys(this.data).forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-${ index }`]
        if (checkbox && checkbox.isActive) {
          checkbox.uncheck()
        }
      })
      this.useFilter()
    },

    useFilter () {
      if (this.title === 'Indikation') {
        this.unselectAllIndications()
      } else if (this.title === 'Wirkstoff') {
        this.unselectAllIngredients()
      }

      Object.keys(this.data).forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-${ index }`]
        if (checkbox && checkbox.isActive) {
          if (this.title === 'Indikation') {
            this.selectIndication(checkbox.value)
          } else if (this.title === 'Wirkstoff') {
            this.selectIngredient(checkbox.value)
          }
        }
      })

      this.$root.events.emit('close-filters')
      this.$root.events.emit('use-filters')
    }
  },

  beforeUnmount () {
    this.$root.events.off('uncheck-all-filters', this.resetFilter)
    this.$root.events.off('uncheck-indication', this.unselectIndicationCheckboxByValue)
    this.$root.events.off('uncheck-ingredient', this.unselectIngredientCheckboxByValue)
    this.$root.events.off('select-all-filters', this.useFilter)
  }
}
</script>

<style lang="stylus">
.generic-filter
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  pointer-events: none
  transition: .3s ease
  max-width: 100%
  width: 100%
  padding: 40px 40px 40px 40px
  background: #FFFFFF
  box-shadow: inset -19px 11px 43px -27px #cccccc
  overflow: hidden

  &-wrapper
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: auto 1fr
    height: 100%

  &.open
    opacity: 1
    pointer-events: all
    display: block

  &-header
    display: flex
    flex-direction: row
    justify-content: flex-start
    padding-bottom: 25px
    border-bottom: 2px solid #ececec

    .title
      font-family: "BMS Humanity Bold"
      font-size: 20px
      flex: 1
      padding: 5px 0px 5px 0px

    .button-container

      button
        width: fit-content
        padding: 15px
        border-radius: 7px
        border: none

      .reset-button
        background: #F6F3F3
        color: #595454
        margin-right: 10px

      .apply-button
        background: #707070
        color: #FFFFFF

  &-content
    padding: 30px 0px 30px 0px
    border-bottom: 2px solid #ececec
    height: 100%
    overflow-x: hidden
    overflow-y: auto

    &-checkboxes
      display: grid
      grid-template-columns: repeat(3, 1fr)
      gap: 10px

</style>
