<template>
  <div class="active-filters">
    <div class="button-container">
      <label v-if="activeFilterCounter > 0">Aktive Filter</label>
      <button v-if="activeFilterCounter > 0" @click="removeAllFilters" name="remove-filters" aria-label="Remove Filters Button">Alle Filter zurücksetzen</button>
    </div>
    <div class="filter-container">
      <div
        v-for="(filter, index) in selectedActiveFilters"
        :key="`filter${index}`"
        :class="classes(filter)"
        class="filter"
        @click="removeFilter(filter)"
      >
        {{ filter.value }}
        <svg class="remove" width="9px" height="8px" viewBox="0 0 9 8">
          <path fill-rule="evenodd" stroke-linecap="round" fill="none" stroke-width="1" d="M0.518471882,0.522707055 L4.03322711,4.04720386 L7.51279777,7.53641857 M7.51279777,0.522707055 L0.518471882,7.53641857" id="Combined-Shape" stroke="#595454"></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'v-active-filters',

  computed: {
    ...mapGetters([
      'selectedActiveFilters',
      'selectedSearchTerms',
      'activeFilterCounter'
    ])
  },

  methods: {
    ...mapActions([
      'unselectAllFilters',
      'unselectIndication',
      'unselectIngredient',
      'unselectStatus',
      'unselectPhase',
      'deleteSearchTerm'
    ]),

    classes (filter) {
      return {
        recruiting: filter.value === 'Recruiting',
        'not-recruiting': filter.value === 'Not Yet Recruiting',
        active: filter.value === 'Active, not recruiting',
        completed: filter.value === 'Completed',
        'search-term': this.selectedSearchTerms.includes(filter.value)
      }
    },

    removeFilter (filter) {
      if (filter.type === 'indication') {
        this.unselectIndication(filter.value)
        this.$root.events.emit('uncheck-indication', filter.value)
        this.$root.events.emit('use-filters')
      } else if (filter.type === 'ingredient') {
        this.unselectIngredient(filter.value)
        this.$root.events.emit('uncheck-ingredient', filter.value)
        this.$root.events.emit('use-filters')
      } else if (filter.type === 'status') {
        this.unselectStatus(filter.value)
        this.$root.events.emit('uncheck-status', filter.value)
        this.$root.events.emit('use-filters')
      } else if (filter.type === 'phase') {
        this.unselectPhase(filter.value)
        this.$root.events.emit('uncheck-phase', filter.value)
        this.$root.events.emit('use-filters')
      } else if (filter.type === 'searchTerm') {
        this.deleteSearchTerm(filter.value)
        this.$root.events.emit('delete-value')
        this.$root.events.emit('use-filters')
      }
    },

    removeAllFilters () {
      this.unselectAllFilters()
      this.$root.events.emit('uncheck-all-filters')
      this.$root.events.emit('use-filters')
    }
  }

}
</script>

<style lang="stylus">
.active-filters
  display: grid
  grid-template-columns: auto 1fr
  width: 100%
  font-family: "BMS Humanity Regular"
  font-size: 12px

  ::-webkit-scrollbar
    display: none

  .button-container
    display: grid
    grid-template-rows: 12px 1fr
    gap: 5px

    button
      width: 148px
      height: 32px
      background: #595454
      color: white
      font-family: "BMS Humanity Regular"
      font-size: 12px
      border-radius: 20px
      border: none
      margin-right: 5px

  .filter-container
    display: flex
    align-self: flex-end
    flex: 1
    height: 32px
    overflow-x: scroll
    scrollbar-width: none

    .filter
      width: fit-content
      height: 32px
      border-radius: 20px
      background: #F6F3F3
      color: #595454
      padding: 11px
      text-align: center
      margin-right: 5px
      white-space: nowrap
      user-select: none
      cursor: pointer

      svg
        margin-left: 2px
        cursor: pointer

      &.search-term
        background: #FFBA4A

      &.recruiting
        background: #01c370
        color: #FFFFFF

        path
          stroke: #FFFFFF

      &.not-recruiting
        background: #33d6f1
        color: #FFFFFF

        path
          stroke: #FFFFFF

      &.active
        background: #ffba4a
        color: #FFFFFF

        path
          stroke: #FFFFFF

      &.completed
        background: #a69f9f
        color: #FFFFFF

        path
          stroke: #FFFFFF

</style>
