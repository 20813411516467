<template>
  <div class="map-container">
    <div class="error" v-if="offline === true">
      <h1>Bitte stellen Sie eine Internetverbindung her.</h1>
    </div>
    <div class="error" v-else-if="!hasCards">
      <h1>Keine Studien zu Ihrer Auswahl gefunden. Bitte ändern Sie ihre Filter</h1>
    </div>
    <div class="error" v-else-if="!hasCities && $route.name === 'card-detail'">
      <h1>Keine Standorte gefunden</h1>
    </div>
    <div class="error" v-else-if="!hasCities">
      <h1>Keine Studien mit Studienorten zu Ihrer Auswahl gefunden.
      Bitte schalten Sie in die <a href="#" @click="showListView">Listenansicht</a>
      </h1>
    </div>
    <div id="map" :style="{ opacity: hasCards ? 1 : 0 }"></div>

    <div ref="card-modal" class="card-modal" :class="cardModalClasses">
      <div class="wall"></div>
      <div class="modal-wrapper">
        <img src="../assets/icons/close-icon.svg" class="close-button" @click="closeModal" width="23.867" height="23.867" alt="close button">
        <div class="scroll-container">
          <template v-for="(card, index) in selectedCards" :key="`card-${ index }`">
            <v-card
              v-if="card"
              :card="card"
              @click="showDetail"
              overflow
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Map, tileLayer, Marker, LatLngBounds, divIcon } from 'leaflet'
import { MarkerClusterGroup } from 'leaflet.markercluster'
import vCard from './v-card.vue'
import { mapActions, mapGetters } from 'vuex'

import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'

/* eslint-disable */
const original_initIcon = Marker.prototype._initIcon
const originalsetIcon = Marker.prototype.setIcon

Marker.include({
  setIcon: function (icon, id) {
    this.options.id = id
    originalsetIcon.call(this, icon)
  },
  _initIcon: function () {
    original_initIcon.call(this)
    if (this.options.id) {
      this._icon.id = "marker-" + this.options.id
    }
  }
})
MarkerClusterGroup.include({
  setIcon: function (icon, id) {
    this.options.id = id
    originalsetIcon.call(this, icon)
  },
  _initIcon: function () {
    original_initIcon.call(this)
    if (this.options.id) {
      this._icon.id = "marker-" + this.options.id
    }
  }
})
/* eslint-enable */

export default {
  name: 'v-map',

  components: {
    vCard
  },

  data: () => ({
    activeTarget: null,
    layers: [],
    selectedCards: [],
    isCardModalOpen: false
  }),

  props: {
    study: {
      type: Object,
      default: () => null
    },
    noModal: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    studies: {
      handler () {
        this.cleanMap()
        this.setupMap()
      },
      deep: true
    },
    filteredStudies: {
      handler () {
        if (this.study === null) {
          this.cleanMap()
          this.setupMap()
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      'selectedMapStudies',
      'filteredStudies',
      'selectedMapStudy',
      'isAnyFilterSet',
      'mapState',
      'studies'
    ]),

    cardModalClasses () {
      return {
        open: this.isCardModalOpen
      }
    },

    preparedStudies () {
      if (this.study !== null) {
        return [this.study]
      }

      return this.isAnyFilterSet === true ? this.filteredStudies : this.studies
    },

    offline () {
      return window.navigator.onLine === false
    },

    hasCities () {
      return this.preparedStudies.map(study => study.cities.map(city => city)).flat().length > 0
    },

    hasCards () {
      return this.preparedStudies.length > 0
    }
  },

  mounted () {
    this.setupMap()
    if (this.selectedMapStudies.studyIds.length > 0) {
      this.selectedCards = this.selectedMapStudies.studyIds
      if (!this.noModal) {
        this.isCardModalOpen = true
      }
    }

    const iconId = this.selectedMapStudies.iconId || null
    if (iconId) {
      const icon = document.getElementById(iconId)
      if (icon) {
        this.setActiveTarget(icon)
      }
    }

    this.$root.events.on('close-all-modals', this.closeModal)

    // this.$root.events.on('select-all-filters', )// method to use map filter
    this.$root.events.on('uncheck-all-filters', this.clearMapState)
    this.$root.events.on('global-click', this.onGlobalClick)
  },

  methods: {
    ...mapActions([
      'selectMapStudies',
      'unselectMapStudies',
      'setCurrentStudy',
      'setMapState',
      'unsetMapState'
    ]),

    clearMapState () {
      this.unsetMapState()
    },

    onGlobalClick (target) {
      if (this.$route.name === 'card-detail') {
        return
      }
      if (this.isCardModalOpen) {
        const modal = this.$refs['card-modal']
        let modalTarget = target
        if (modalTarget !== modal) {
          let modalTargetFound = false
          while (modalTarget.parentNode) {
            modalTarget = modalTarget.parentNode
            if (modalTarget === modal) {
              modalTargetFound = true
            }
          }
          if (!modalTargetFound) {
            this.closeModal()
          }
        }
      }
    },

    showListView () {
      this.$emit('show-listview')
    },

    closeModal () {
      this.isCardModalOpen = false
      this.selectedCards = []
      this.unselectMapStudies()
      if (this.activeTarget) {
        this.activeTarget.classList.remove('active')
      }
      this.activeTarget = null
    },

    setupMap () {
      if (this.preparedStudies.length > 0 && this.hasCities) {
        this.setMapView()

        // Place markers on map
        this.getMap().addLayer(this.getMarkers())

        // Used to load and display tile layers on the map
        // Most tile servers require attribution, which you can set under `Layer`
        // Note: Attribution needs to be in there!!!
        tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.getMap())

        // Only persist Map State if none perticular study was provided as prop to the component (ergo, not detail view)
        if (!this.study) {
          this.getMap().on('zoomend', this.storeMapState)
          this.getMap().on('moveend', this.storeMapState)
        }
      }
    },

    storeMapState () {
      this.setMapState({
        zoom: this.getMap().getZoom(),
        center: this.getMap().getCenter()
      })
    },

    setMapView () {
      if (!this.study && this.mapState && this.mapState.zoom && this.mapState.center) {
        this.getMap().setView(this.mapState.center)
        this.getMap().setZoom(this.mapState.zoom)
      } else {
        // Set map bound to combination if available lat/longs, so map is centered
        this.getMap().fitBounds(new LatLngBounds(this.getMapBounds()))
      }
    },

    cleanMap () {
      this.getMap().eachLayer(layer => this.getMap().removeLayer(layer))
    },

    getMarkers () {
      // Cluster Locations
      const cluster = new MarkerClusterGroup({
        spiderfyOnMaxZoom: this.study !== null,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        iconCreateFunction: (cluster) => {
          const icon = divIcon({
            html: '<b class="cluster-counter">' + cluster.getChildCount() + '</b>'
          })

          const latLng = cluster._latlng
          cluster.options.id = `cluster-${ latLng.lat }-${ latLng.lng }`

          return icon
        }
      })

      cluster.on('clusterclick', (e) => {
        const markers = e.layer.getAllChildMarkers()
        const clusters = e.layer._childClusters

        if (clusters.length === 0) {
          if (markers.length > 0) {
            const [marker] = markers
            const latLng = marker.getLatLng()
            const clickedStudies = []

            this.preparedStudies.forEach(study => {
              study.cities.forEach(city => {
                if (
                  city['Site Location (Latitude)'] === latLng.lat &&
                  city['Site Location (Longitude)'] === latLng.lng
                ) {
                  clickedStudies.push(study)
                }
              })
            })

            this.markerClicked(e, clickedStudies)
          }
        }
      })

      // Create Marker for each location and add to cluster layer
      this.preparedStudies.forEach(study => {
        study.cities.forEach(city => {
          const layer = new Marker(
            [city['Site Location (Latitude)'], city['Site Location (Longitude)']],
            {
              icon: divIcon({
                html: '<b>1</b>'
              }),
              title: city.name,
              riseOnHover: true
            }
          )

          const iconId = `${ study['NCT Id Grouping Code'] }-${ city['Site Name'] }`
          layer.options.id = iconId

          cluster.addLayer(
            layer
              .on('click', (event) => this.markerClicked(event, [study]))
              .bindPopup(city['Site Name'])
          )
        })
      })

      return cluster
    },

    getMapBounds () {
      return this.studies.map(study => {
        return study.cities.map(city => [
          city['Site Location (Latitude)'],
          city['Site Location (Longitude)']
        ])
      }).flat()
    },

    markerClicked (event, clickedStudies) {
      setTimeout(() => {
        if (!this.noModal) {
          this.isCardModalOpen = true
        }

        const coords = {
          lat: event.latlng.lat,
          lng: event.latlng.lng
        }

        const icon = event.sourceTarget._icon
        this.setActiveTarget(icon)

        let siteName = ''
        clickedStudies.forEach(study => {
          study.cities.forEach(city => {
            if (
              city['Site Location (Latitude)'] === coords.lat &&
              city['Site Location (Longitude)'] === coords.lng
            ) {
              siteName = city['Site Name']
            }
          })
        })

        const studiesToSelect = {
          iconId: icon.id || null,
          coords,
          siteName,
          studyIds: clickedStudies
        }

        this.selectMapStudies(studiesToSelect)
        this.selectedCards = clickedStudies
        this.$emit('clicked', clickedStudies)
      }, 1)
    },

    setActiveTarget (target) {
      if (this.activeTarget) {
        this.activeTarget.classList.remove('active')
        this.activeTarget = null
      }

      if (target) {
        this.activeTarget = target
        this.activeTarget.classList.add('active')
      }
    },

    getMap () {
      if (!this.map) {
        this.map = new Map('map', {
          minZoom: 5,
          maxZoom: 18
        }).setView([0, 0], 1)
      }

      return this.map
    },

    showDetail () {
      this.$router.push('/card-detail')
    }
  },

  beforeUnmount () {
    this.$root.events.off('close-all-modals', this.closeModal)
    this.$root.events.off('global-click', this.onGlobalClick)
  }
}
</script>

<style lang="stylus">
.map-container
  position: relative

  .card-modal
    position: absolute
    display: flex
    justify-content: center
    opacity: 0
    pointer-events: none
    width: 100%
    height: auto
    min-height: 23%
    max-height: 50%
    left: 0
    bottom: 0
    scrollbar-color: #c1c1c1 transparent
    scrollbar-width: 10px

    ::-webkit-scrollbar
      background: transparent
      width: 10px

    ::-webkit-scrollbar-thumb
      background: #c1c1c1
      border-radius: 8px

    &.open
      opacity: 1
      pointer-events: all
      z-index: 5000

    .wall
      position: absolute
      background: #747474
      opacity: .6
      width: 100%
      height: 100%
      z-index: 1001

    .modal-wrapper
      position: relative
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: center
      width: 100%
      background: transparent
      padding: 60px 40px 40px 40px
      z-index: 1002

      .close-button
        position: absolute
        right: 30px
        top: 25px
        cursor: pointer

      .scroll-container
        overflow: auto
        overflow-x: hidden
        padding-right: 5px

        .card.overflow
          position: unset
          bottom: 40px
          width: 100%
          left: 40px
          right: 40px
          z-index: 2000
          margin-bottom: 10px

  #map
    height: 100%

    .leaflet-marker-icon
      width: 40px !important
      height: 40px !important
      border-radius: 40px
      background-color: white
      display: flex
      justify-content: center
      align-items: center
      border: 1px #aaa solid
      box-shadow: 2px 2px 5px #888888

      b
        color: #be2bbb
        font-weight: 700
        font-size: 18px

      &.active
        background-color: #be2bbb
        color: white
        width: 50px !important
        height: 50px !important

        b
          color: white
          font-size: 22px

    .leaflet-popup
      margin-left: 20px
      margin-top: 0px
</style>
