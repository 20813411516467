<template>
  <div class="upload-form">
    <h1>
      <strong>BMS Study Hub</strong>
      <br>Data Transformation Service
    </h1>

    <div id="loading">
      <div v-if="processing" class="dot-pulse" />
    </div>

    <div id="error" v-if="hasErrors" v-html="errorMessage" />

    <div id="success" v-show="isSuccessful">
      Data transformation successfully done.&nbsp;<a id="download">Download Report</a>
    </div>

    <form id="form" @submit.prevent="submit">
      <div class="form-group">
        <label for="token">
          Token <span>The access token is required for uploading and processing files</span>
        </label>
        <input
          name="token"
          type="password"
          id="token"
          required="required"
          class="form-controll"
        />
      </div>

      <div class="form-group file-area">
        <label for="fileInput">
          Excel Files<span>Please select required xlsx files. File names must begin as indicated</span>
        </label>
        <input
          type="file"
          name="files"
          id="fileInput"
          required="required"
          multiple="multiple"
          @change="onFileChange"
        />

        <div class="file-dummy">
          <div v-if="files" class="success">
            <ul>
              <template v-for="(list, group, index) in files" :key="`filekey-${ index }`">
                <li v-for="(file, index) in list" :key="`file-${ index }`" :class="`is-${ group }`">
                  {{ file }}
                </li>
              </template>
            </ul>
          </div>
          <div v-else class="default">
            <ul>
              <li v-for="file in requiredFiles" :key="file">{{ file }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>
          Download latest Excel Files
            <span>
              <a :href="`${ bucketUrl }/whitelist.xlsx`">whitelist.xlsx</a>
              &nbsp; &middot; &nbsp;
              <a :href="`${ bucketUrl }/mappings.xlsx`">mappings.xlsx</a>
          </span>
        </label>
      </div>

      <div class="form-group">
        <button @click="submit" :disabled="processing">
          Upload and Processing
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'v-upload-form',

  data: () => ({
    requiredFiles: [
      'whitelist',
      'mappings'
    ],
    files: null,
    processing: false,
    hasErrors: false,
    errorMessage: '',
    isSuccessful: false,
    downloadUrl: '',
    bucketUrl: process.env?.VUE_APP_BUILD_FOR_STAGE === 'true'
      ? 'https://storage.googleapis.com/rmh-client-bms-studyhub-staging'
      : 'https://storage.googleapis.com/rmh-client-bms-studyhub'
  }),

  methods: {
    onFileChange (e) {
      const selectedFiles = e.target.files

      if (selectedFiles.length > 0) {
        const fileNames = Array.from(selectedFiles).map(file => file.name)

        this.files = {
          valid: fileNames.filter(file => this.requiredFiles.some(reqFile => file.startsWith(reqFile))),
          missing: this.requiredFiles.filter(reqFile => !fileNames.some(file => file.startsWith(reqFile))),
          invalid: fileNames.filter(file => !this.requiredFiles.some(reqFile => file.startsWith(reqFile)))
        }
      }
    },

    async submit () {
      this.processing = true
      this.errorMessage = ''
      this.hasErrors = false

      const formEl = document.getElementById('form')
      const formData = new FormData(formEl)

      const url = process.env?.VUE_APP_BUILD_FOR_STAGE === 'true'
        ? 'https://studyhub-service-staging-vf4f4mg4vq-ey.a.run.app/upload'
        : 'https://studyhub-service-production-vf4f4mg4vq-ey.a.run.app/upload'

      const response = await fetch(url, {
        method: 'POST',
        body: formData
      })

      if (response.status === 200 || response.status === 201) {
        const downloadEl = document.getElementById('download')
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const currentDate = new Date().toISOString().slice(0, 10)
        downloadEl.href = url
        downloadEl.download = `${ currentDate }_study-hub-upload-report.csv`

        formEl.reset()
        this.isSuccessful = true
      } else {
        this.hasErrors = true
        const error = JSON.parse(await response.text())
        const message = {
          error: error.error ? `<strong>${ error.error }</strong>:` : '',
          message: error.message ? error.message : 'Sorry, something went wrong.'
        }
        this.errorMessage = `${ message.error } ${ message.message }`
      }

      this.processing = false
    }
  }
}
</script>

<style lang="stylus">
.upload-form
  --primary-color: #BD2BBA
  --secondary-color: #585353
  --dot-color: #585353

  display: grid
  grid-template-rows: auto 1fr
  height: calc(100% - 79px)
  max-width: 100%
  width: 100%
  font-size: 14px
  font-family: "BMS Humanity Regular"
  color: white
  background: var(--primary-color)
  background: linear-gradient(to bottom, var(--primary-color) 0%, white 100%)
  background-repeat: no-repeat
  background-position: center

  #loading
    position: fixed
    z-index: 100000000
    top: 0
    right: 39px
    width: 200px
    height: 48px
    background: white
    display: flex
    justify-content: flex-end
    align-items: center
    flex-direction: row

  /* === Error response ===*/

  #error, #success
    position: absolute
    bottom: 0
    width: 100%
    background: var(--secondary-color)
    font-family: "BMS Humanity Light"
    padding: 20px
    opacity: 0
    display: flex
    justify-content: center
    align-items: center
    z-index: 2
    animation: fadeIn 300ms ease-in both

  #success
    background: var(--primary-color)
    color: white

    a
      font-family: "BMS Humanity Bold"
      color: white

  strong
    font-weight: normal
    font-family: "BMS Humanity Bold"

  h1, h2
    text-align: center
    margin: 50px auto
    font-family: "BMS Humanity Light"

  /* === FORM === */

  input
    color: white

  label
    display: block
    margin: 4px 0
    text-transform: uppercase
    overflow: hidden

    span
      float: right
      text-transform: none
      line-height: 1em
      font-family: "BMS Humanity Light"
      opacity: 0.8

      a
        color: white

  button
    padding: 10px 30px
    background: rgba(255, 255, 255, 0.8)
    color:  var(--primary-color)
    border: 0
    cursor: pointer
    text-transform: uppercase

  .form-controll
    display: block
    padding: 8px 16px
    width: 100%
    background-color: rgba(255, 255, 255, 0.2)
    border: 1px solid rgba(255, 255, 255, 0.3)

  .form-controll:focus
    outline: 2px solid rgba(255, 255, 255, 0.5)
    outline: -webkit-focus-ring-color auto 5px

  .form-group
    max-width: 600px
    margin: auto
    margin-bottom: 30px

  /* === FILE INPUT === */

  .file-area
    width: 100%
    position: relative

  .file-area input[type='file']
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    right: 0
    bottom: 0
    opacity: 0
    cursor: pointer

  .file-area .file-dummy
    width: 100%
    padding: 30px
    background: rgba(255, 255, 255, 0.2)
    border: 2px dashed rgba(255, 255, 255, 0.2)
    transition: background 0.3s ease-in-out
    color: rgba(255, 255, 255, 0.6)

  .file-area .file-dummy .success
    display: none

  .file-area:hover .file-dummy
    background: rgba(255, 255, 255, 0.1)

  .file-area input[type='file']:focus + .file-dummy
    outline: 2px solid rgba(255, 255, 255, 0.5)
    outline: -webkit-focus-ring-color auto 5px

  .file-area input[type='file']:valid + .file-dummy
    background: rgba(255, 255, 255, 0.1)

  .file-area input[type='file']:valid + .file-dummy .success
    display: inline-block

  .file-area input[type='file']:valid + .file-dummy .default
    display: none

  .file-area ul
    margin: 0
    padding: 0

  .file-area ul li
    padding: 0.1em 0

  .file-area ul li.is-valid
    color: white

  .file-area ul li.is-invalid
    text-decoration: line-through

  @keyframes fadeIn
    from
      opacity: 0
      transform: translate3d(0, +100%, 0)
    to
      opacity: 1
      transform: translate3d(0, 0, 0)

  /* === Loading spinner === */

  .dot-pulse
    position: relative
    left: -9999px
    width: 10px
    height: 10px
    border-radius: 5px
    background-color: var(--dot-color)
    color: var(--dot-color)
    box-shadow: 9999px 0 0 -5px
    animation: dot-pulse 1.5s infinite linear
    animation-delay: 0.25s

  .dot-pulse::before, .dot-pulse::after
    content: ""
    display: inline-block
    position: absolute
    top: 0
    width: 10px
    height: 10px
    border-radius: 5px
    background-color: var(--dot-color)
    color: var(--dot-color)

  .dot-pulse::before
    box-shadow: 9984px 0 0 -5px
    animation: dot-pulse-before 1.5s infinite linear
    animation-delay: 0s

  .dot-pulse::after
    box-shadow: 10014px 0 0 -5px
    animation: dot-pulse-after 1.5s infinite linear
    animation-delay: 0.5s

  @keyframes dot-pulse-before
    0%
      box-shadow: 9984px 0 0 -5px
    30%
      box-shadow: 9984px 0 0 2px
    60%, 100%
      box-shadow: 9984px 0 0 -5px

  @keyframes dot-pulse
    0%
      box-shadow: 9999px 0 0 -5px
    30%
      box-shadow: 9999px 0 0 2px
    60%, 100%
      box-shadow: 9999px 0 0 -5px

  @keyframes dot-pulse-after
    0%
      box-shadow: 10014px 0 0 -5px
    30%
      box-shadow: 10014px 0 0 2px
    60%, 100%
      box-shadow: 10014px 0 0 -5px
</style>
