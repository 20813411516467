<template>
  <div class="card-overview" :class="{ blur: openPopupCounter > 0 }">
    <div class="header-container">
      <div class="filter-wrapper">
        <v-toolbar
          ref="toolbar"
          @switch-view="switchView"
          @open-popup="openPresetModal"
        >
          <v-generic-filter
            title="Indikation"
            :open="isIndicationOpen"
            :data="indicationData"
          >
          </v-generic-filter>

          <v-generic-filter
            title="Wirkstoff"
            :open="isIngredientOpen"
            :data="ingredientData"
          >
          </v-generic-filter>
        </v-toolbar>
      </div>
      <div class="active-filter-wrapper">
        <v-active-filters></v-active-filters>
      </div>
    </div>
    <div class="card-container" v-if="view === 'card'">
      <v-card
        v-for="(card, index) in filteredCards"
        :key="`card-${ index }`"
        :card="card"
      />
      <h1 class="error" v-if="!hasCards">Keine Studien zu Ihrer Auswahl gefunden. Bitte ändern Sie ihre Filter</h1>
    </div>

    <v-map
      v-else-if="view === 'map'"
      :studies="filteredStudies"
      @show-listview="switchViewManually('card')"
    />
  </div>
  <v-preset-modal :open="isPresetOpen" @close-modal="closePresetModal"></v-preset-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import vCard from '../components/v-card.vue'
import vToolbar from '../components/v-toolbar.vue'
import vActiveFilters from '../components/v-active-filters.vue'
import vGenericFilter from '../components/v-generic-filter.vue'
import vMap from '../components/v-map.vue'
import vPresetModal from '../components/v-preset-modal.vue'

export default {
  name: 'v-card-overview',

  data: () => ({
    isIndicationOpen: false,
    isIngredientOpen: false,
    isPresetOpen: false,
    filteredCards: [],
    view: 'card'
  }),

  components: {
    vCard,
    vToolbar,
    vGenericFilter,
    vActiveFilters,
    vMap,
    vPresetModal
  },

  created () {
    this.$root.events.on('open-indication', this.openIndication)
    this.$root.events.on('close-indication', this.closeIndication)
    this.$root.events.on('open-ingredient', this.openIngredient)
    this.$root.events.on('close-ingredient', this.closeIngredient)
  },

  mounted () {
    if (this.presetFilters) {
      this.view = this.presetFilters.view
    }
    this.filterCards()
    this.$root.events.on('use-filters', this.filterCards)

    if (this.selectedMapStudies.studyIds.length > 0 || this.selectedView === 'map') {
      this.view = 'map'
    }
  },

  computed: {
    ...mapGetters([
      'studies',
      'filters',
      'selectedIndications',
      'selectedIngredients',
      'filteredStudies',
      'openPopupCounter',
      'selectedMapStudies',
      'selectedView',
      'presetFilters'
    ]),

    indicationData () {
      return this.filters.indication
    },

    ingredientData () {
      return this.filters.substance
    },

    hasCards () {
      return this.filteredStudies.length > 0
    }
  },

  methods: {

    ...mapActions([
      'unselectMapStudies',
      'selectView'
    ]),

    openPresetModal () {
      this.isPresetOpen = true
    },

    closePresetModal () {
      this.isPresetOpen = false
    },

    openIndication () {
      this.isIndicationOpen = true
    },

    closeIndication () {
      this.isIndicationOpen = false
    },

    openIngredient () {
      this.isIngredientOpen = true
    },

    closeIngredient () {
      this.isIngredientOpen = false
    },

    filterCards () {
      this.filteredCards = this.filteredStudies
    },

    switchView (view) {
      this.view = view
      this.selectView(view)
    },

    switchViewManually (view) {
      this.switchView(view)

      const toolbar = this.$refs.toolbar
      if (toolbar) {
        toolbar.switchView(view)
      }
    }
  },

  beforeUnmount () {
    this.$root.events.off('open-indication')
    this.$root.events.off('close-indication')
    this.$root.events.off('open-ingredient')
    this.$root.events.off('close-ingredient')
    this.$root.events.off('use-filters')
  }
}
</script>

<style lang="stylus">
.card-overview
  display: grid
  grid-template-rows: auto 1fr
  height: calc(100% - 79px)
  max-width: 100%
  width: 100%
  margin-top: 30px

  .leaflet-popup-content-wrapper
    display: none

  .leaflet-popup-tip-container
    display: none

  .leaflet-popup-close-button
    display: none

  .header-container
    grid-row: 1
    padding: 0px 40px 5px 40px
    z-index: 2
    max-width: 100%
    width: 100%
    box-shadow: 0px 9px 12px 0px #d3d3d3

    .filter-wrapper
      display: flex
      justify-content: space-between
      align-items: center
      flex-direction: row
      margin-bottom: 15px

    .active-filter-wrapper
      display: flex
      justify-content: flex-start
      align-items: center
      flex-direction: row

  .card-container
    grid-row: 2
    max-width: 100%
    width: 100%
    padding: 40px 40px 40px 40px
    background: #e8e4e3
    overflow-x: hidden
    overflow-y: scroll

    .card:last-of-type
      margin-bottom: 0

  .generic-filter
    grid-row: 2
    margin-top: 10px

  .map-container
    z-index: 1

</style>
