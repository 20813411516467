import { LatLng } from 'leaflet'
import { createStore } from 'vuex'

const initialFilterState = {
  indications: [],
  ingredients: [],
  status: [],
  phase: [],
  age: [],
  searchTerms: [],
  view: 'card',
  location: {
    coords: null, // LatLng Object of postalcode / city in location filter
    input: '', // PLZ/ort input field value
    distance: null // distance in km
  },
  mapStudies: {
    iconId: null,
    siteName: '',
    coords: null,
    studyIds: []
  }
}

export default createStore({
  state: {
    studies: [],
    filters: {},
    current: null,
    searchTermList: [],
    openPopupCounter: 0,
    presetFilters: null,
    selected: { ...initialFilterState },
    mapState: {
      zoom: null,
      center: null
    },
    presetMapState: null
  },
  getters: {
    filters (state) {
      return state.filters
    },

    currentStudy (state) {
      return state.current
    },

    studies (state) {
      return state.studies
    },

    isAnyFilterSet (state) {
      return state.selected.indications.length > 0 ||
        state.selected.ingredients.length > 0 ||
        state.selected.status.length > 0 ||
        state.selected.phase.length > 0 ||
        state.selected.age.length > 0 ||
        state.selected.searchTerms.length > 0 ||
        state.selected.location.coords !== null
    },

    filteredStudies (state, getters) {
      // Return all studies if no filters
      if (getters.isAnyFilterSet === false) {
        return state.studies
      }

      // Indication filter
      let indicationStudyIds = []
      if (state.selected.indications.length > 0) {
        state.selected.indications.forEach(indication => {
          indicationStudyIds = [
            ...indicationStudyIds,
            ...(state.filters.indication[indication] || [])
          ]
        })
      }

      // Ingredient filter
      let ingredientStudyIds = []
      if (state.selected.ingredients.length > 0) {
        state.selected.ingredients.forEach(ingredient => {
          ingredientStudyIds = [
            ...ingredientStudyIds,
            ...(state.filters.substance[ingredient] || [])
          ]
        })
      }

      // Status filter
      let statusStudyIds = []
      if (state.selected.status.length > 0) {
        state.selected.status.forEach(status => {
          statusStudyIds = [
            ...statusStudyIds,
            ...(state.filters.status[status] || [])
          ]
        })
      }

      // Phase filter
      let phaseStudyIds = []
      if (state.selected.phase.length > 0) {
        state.selected.phase.forEach(phase => {
          phaseStudyIds = [
            ...phaseStudyIds,
            ...(state.filters.phase[phase] || [])
          ]
        })
      }

      // Age filter
      let ageStudyIds = []
      if (state.selected.age.length > 0) {
        console.log(state.selected.age)
        state.selected.age.forEach(age => {
          ageStudyIds = [
            ...ageStudyIds,
            ...(state.filters.age[age] || [])
          ]
        })
      }

      // Location filter
      const locationStudies = []
      if (state.selected.location && state.selected.location.coords && state.selected.location.distance) {
        state.studies.filter(study => {
          // Is any City in the study within the distance of the search location?
          return study.cities.find(city => {
            const loc = new LatLng(city['Site Location (Latitude)'], city['Site Location (Longitude)'])

            return (state.selected.location.coords.distanceTo(loc) / 1000) <= state.selected.location.distance
          })
        }).forEach(study => {
          locationStudies.push(study['NCT Id Grouping Code'])
        })
      }

      // Searchterm filter
      let searchStudyIds = []
      if (state.searchTermList[state.selected.searchTerms]) {
        searchStudyIds = [
          ...searchStudyIds,
          ...(state.searchTermList[state.selected.searchTerms] || [])
        ]
      }

      // Combine
      const studyIds = [
        indicationStudyIds,
        ingredientStudyIds,
        statusStudyIds,
        phaseStudyIds,
        ageStudyIds,
        searchStudyIds,
        locationStudies
      ]

      // Filters must be AND cobined, so filter out IDs that don't occur in every Singlefilter array
      return [...new Set(studyIds.flat())]
        .filter(item => studyIds.every(filterIds => filterIds.length > 0
          ? filterIds.includes(item)
          : true
        ))
        .map(id => state.studies.find(study => study['NCT Id Grouping Code'] === id))
    },

    activeFilterCounter (state) {
      let counter = 0

      Object.keys(state.selected).forEach(key => {
        if (Array.isArray(state.selected[key])) {
          counter += state.selected[key].length
        }
      })

      return counter
    },

    presetFilters (state) {
      return state.presetFilters
    },

    selectedView (state) {
      return state.selected.view
    },

    selectedMapStudies (state) {
      return state.selected.mapStudies
    },

    selectedSiteNameStudy (state) {
      return state.selected.siteNameStudy
    },

    selectedIndications (state) {
      return state.selected.indications
    },

    selectedIngredients (state) {
      return state.selected.ingredients
    },

    selectedStatus (state) {
      return state.selected.status
    },

    selectedPhase (state) {
      return state.selected.phase
    },

    selectedAge (state) {
      return state.selected.age
    },

    searchTermList (state) {
      return state.searchTermList
    },

    selectedSearchTerms (state) {
      return state.selected.searchTerms
    },

    selectedActiveFilters (state) {
      const searchTerms = state.selected.searchTerms.map(term => ({
        type: 'searchTerm',
        value: term
      }))

      const indications = state.selected.indications.map(indication => ({
        type: 'indication',
        value: indication
      }))

      const ingredients = state.selected.ingredients.map(ingredient => ({
        type: 'ingredient',
        value: ingredient
      }))

      const status = state.selected.status.map(status => ({
        type: 'status',
        value: status
      }))

      const phase = state.selected.phase.map(phase => ({
        type: 'phase',
        value: phase
      }))

      const age = state.selected.age.map(age => ({
        type: 'age',
        value: age
      }))

      return [...new Set([
        ...searchTerms,
        ...indications,
        ...ingredients,
        ...status,
        ...phase,
        ...age
      ])]
    },

    openPopupCounter (state) {
      return state.openPopupCounter
    },

    mapState (state) {
      return state.mapState
    }
  },
  mutations: {
    SET_STUDIES (state, studies) {
      state.studies = studies
    },
    SET_FILTERS (state, filters) {
      state.filters = filters
    },
    SET_SELECTED (state, selected) {
      state.selected = selected
    },
    SET_SEARCHTERMS (state, searchTerms) {
      state.searchTermList = searchTerms
    },
    SET_CURRENT_STUDY (state, study) {
      state.current = study
    },
    DELETE_CURRENT_STUDY (state) {
      state.current = null
    },
    SELECT_MAP_STUDIES (state, studies) {
      state.selected.mapStudies = studies
    },
    UNSELECT_MAP_STUDIES (state) {
      state.selected.mapStudies = {
        iconId: null,
        siteName: '',
        coords: null,
        studyIds: []
      }
    },
    SELECT_INDICATION (state, indication) {
      if (!state.selected.indications.includes(indication)) {
        state.selected.indications.push(indication)
      }
    },
    UNSELECT_INDICATION (state, indication) {
      state.selected.indications = state.selected.indications
        .filter(item => item !== indication)
    },
    UNSELECT_ALL_INDICATIONS (state) {
      state.selected.indications = []
    },
    SELECT_INGREDIENT (state, ingredient) {
      if (!state.selected.ingredients.includes(ingredient)) {
        state.selected.ingredients.push(ingredient)
      }
    },
    UNSELECT_INGREDIENT (state, ingredient) {
      state.selected.ingredients = state.selected.ingredients
        .filter(item => item !== ingredient)
    },
    UNSELECT_ALL_INGREDIENTS (state) {
      state.selected.ingredients = []
    },
    SELECT_STATUS (state, status) {
      if (!state.selected.status.includes(status)) {
        state.selected.status.push(status)
      }
    },
    UNSELECT_STATUS (state, status) {
      state.selected.status = state.selected.status
        .filter(item => item !== status)
    },
    UNSELECT_ALL_STATUS (state) {
      state.selected.status = []
    },
    SELECT_PHASE (state, phase) {
      if (!state.selected.phase.includes(phase)) {
        state.selected.phase.push(phase)
      }
    },
    UNSELECT_PHASE (state, phase) {
      state.selected.phase = state.selected.phase
        .filter(item => item !== phase)
    },
    UNSELECT_ALL_PHASES (state) {
      state.selected.phase = []
    },
    SELECT_AGE (state, age) {
      if (!state.selected.age.includes(age)) {
        state.selected.age = [age]
      }
    },
    SELECT_ALL_AGES (state) {
      state.selected.age = []
    },
    SET_SEARCHTERM (state, term) {
      state.selected.searchTerms = [term]
    },
    DELETE_SEARCHTERM (state, term) {
      state.selected.searchTerms = state.selected.searchTerms
        .filter(item => item !== term)
    },
    DELETE_SEARCHTERMS (state) {
      state.selected.searchTerms = []
    },
    SELECT_LOCATION (state, location) {
      state.selected.location = location
    },
    UNSELECT_LOCATION (state) {
      state.selected.location = {
        coords: null,
        input: '',
        distance: null
      }
    },
    SELECT_SITENAMESTUDY (state, sitenamestudy) {
      state.selected.siteNameStudy = sitenamestudy
    },
    UNSELECT_ALL_FILTERS (state) {
      state.selected.indications = []
      state.selected.ingredients = []
      state.selected.status = []
      state.selected.phase = []
      state.selected.age = []
      state.selected.searchTerms = []
      state.mapState = {
        zoom: null,
        center: null
      }
    },
    INCREASE_POPUP_COUNTER (state) {
      state.openPopupCounter += 1
    },
    DECREASE_POPUP_COUNTER (state) {
      state.openPopupCounter -= 1
    },
    SET_MAP_STATE (state, mapState) {
      state.mapState = mapState
    },
    UNSELECT_ALL_PRESET_FILTERS (state) {
      state.presetFilters = null
      state.presetMapState = null
    },
    SET_PRESET_FILTERS (state) {
      state.presetFilters = {
        ...state.selected
      }

      state.presetMapState = {
        ...state.mapState
      }
    },
    USE_PRESET_FILTERS (state) {
      if (state.presetFilters) {
        state.selected = {
          ...state.presetFilters
        }
      } else {
        state.selected = { ...initialFilterState }
      }

      state.mapState = {
        ...state.presetMapState
      }
    },
    SELECT_VIEW (state, view) {
      state.selected.view = view
    }
  },
  actions: {
    setStudies (context, studies) {
      context.commit('SET_STUDIES', studies)
    },
    setFilters (context, filters) {
      context.commit('SET_FILTERS', filters)
    },
    setSelected (context, selected) {
      context.commit('SET_SELECTED', selected)
    },
    setSearchTerms (context, searchTerms) {
      context.commit('SET_SEARCHTERMS', searchTerms)
    },
    setCurrentStudy (context, study) {
      context.commit('SET_CURRENT_STUDY', study)
    },

    deleteCurrentStudy (context) {
      context.commit('DELETE_CURRENT_STUDY')
    },

    selectMapStudies (context, studies) {
      context.commit('SELECT_MAP_STUDIES', studies)
    },

    unselectMapStudies (context) {
      context.commit('UNSELECT_MAP_STUDIES')
    },

    selectIndication (context, indication) {
      context.commit('SELECT_INDICATION', indication)
    },

    unselectIndication (context, indication) {
      context.commit('UNSELECT_INDICATION', indication)
    },

    unselectAllIndications (context) {
      context.commit('UNSELECT_ALL_INDICATIONS')
    },

    selectIngredient (context, ingredient) {
      context.commit('SELECT_INGREDIENT', ingredient)
    },

    unselectIngredient (context, ingredient) {
      context.commit('UNSELECT_INGREDIENT', ingredient)
    },

    unselectAllIngredients (context) {
      context.commit('UNSELECT_ALL_INGREDIENTS')
    },

    selectStatus (context, status) {
      context.commit('SELECT_STATUS', status)
    },

    unselectStatus (context, status) {
      context.commit('UNSELECT_STATUS', status)
    },

    unselectAllStatus (context) {
      context.commit('UNSELECT_ALL_STATUS')
    },

    selectPhase (context, phase) {
      context.commit('SELECT_PHASE', phase)
    },

    unselectPhase (context, phase) {
      context.commit('UNSELECT_PHASE', phase)
    },
    unselectAllPhases (context) {
      context.commit('UNSELECT_ALL_PHASES')
    },

    selectAge (context, age) {
      context.commit('SELECT_AGE', age)
    },

    selectAllAges (context) {
      context.commit('SELECT_ALL_AGES')
    },

    setSearchTerm (context, term) {
      context.commit('SET_SEARCHTERM', term)
    },

    deleteSearchTerm (context, term) {
      context.commit('DELETE_SEARCHTERM', term)
    },

    deleteSearchTerms (context) {
      context.commit('DELETE_SEARCHTERMS')
    },

    selectLocation (context, location) {
      context.commit('SELECT_LOCATION', location)
    },

    selectSiteNameStudy (context, sitenamestudy) {
      context.commit('SELECT_SITENAMESTUDY', sitenamestudy)
    },

    unselectLocation (context) {
      context.commit('UNSELECT_LOCATION')
    },

    unselectAllFilters (context) {
      context.commit('UNSELECT_ALL_FILTERS')
    },

    increasePopupCounter (context) {
      context.commit('INCREASE_POPUP_COUNTER')
    },

    decreasePopupCounter (context) {
      context.commit('DECREASE_POPUP_COUNTER')
    },

    setMapState (context, state) {
      context.commit('SET_MAP_STATE', state)
    },

    unsetMapState (context) {
      context.commit('SET_MAP_STATE', { zoom: null, center: null })
    },

    usePresetFilters (context) {
      context.commit('USE_PRESET_FILTERS')
    },

    setPresetFilters (context) {
      context.commit('SET_PRESET_FILTERS')
    },

    unselectAllPresetFilters (context) {
      context.commit('UNSELECT_ALL_PRESET_FILTERS')
    },

    selectView (context, view) {
      context.commit('SELECT_VIEW', view)
    }
  },
  modules: {
  }
})
