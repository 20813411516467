<template>
  <div ref="dropdown" class="dropdown" @click="onDropdownClick" :style="styles">
    <div class="content-wrapper">
      <slot></slot>
    </div>
    <div class="button-wrapper">
      <button @click="resetFilter" name="reset-filter" aria-label="Reset Button">Zurücksetzen</button>
      <button class="apply" @click="useFilter" name="apply-filter" aria-label="Remove Apply Button">Filter anwenden</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-dropdown',

  data: () => ({
    maxHeight: 0
  }),

  mounted () {
    this.calculateHeight()
    window.addEventListener('resize', this.calculateHeight)
  },

  computed: {
    styles () {
      return {
        maxHeight: `${ this.maxHeight }px`
      }
    }
  },

  methods: {
    onDropdownClick (e) {
      e.stopPropagation()
    },

    resetFilter () {
      this.$emit('reset-filter')
      this.useFilter()
    },

    calculateHeight () {
      const dropdown = this.$refs.dropdown
      const pageHeight = document.body.clientHeight

      if (dropdown) {
        const rect = dropdown.getBoundingClientRect()
        this.maxHeight = pageHeight - rect.y
      }
    },

    useFilter () {
      this.$emit('use-filters')
      this.$root.events.emit('close-filters')
      this.$root.events.emit('use-filters')
    }
  }
}
</script>

<style lang="stylus">
.dropdown
  position: absolute
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 262px
  height: auto
  background: #FFFFFF
  color: #595454
  border-radius: 0px 0px 10px 10px
  box-shadow: 0px 11px 22px 0px #b8b8b8
  overflow: hidden
  opacity: 0
  pointer-events: none
  transition: .3s ease
  left: 0
  top: 100%

  ::-webkit-scrollbar
    display: none

  &.active
    opacity: 1
    pointer-events: all

  .content-wrapper
    padding: 20px
    overflow: auto

    > div
      margin-bottom: 10px

      &:last-of-type
        margin-bottom: 0

  .button-wrapper
    display: flex
    flex-direction: row

    button
      flex: 1
      height: 42px
      border: none
      background: #f6f3f3
      color: #595454

      &.apply
        background: #595454
        color: #FFFFFF
</style>
