<template>
  <div class="toolbar">
    <v-search-bar
      v-show="seachbarOpen"
      @close-searchbar="seachbarOpen = false"
      @open-popup="openPresetModal"
    />

    <v-searchbutton
      v-show="!seachbarOpen"
      @open-searchbar="seachbarOpen = true"
    />
    <v-filterbar v-show="!seachbarOpen" />

    <v-view-buttons
      ref="view-buttons"
      @switch-view="(view) => $emit('switch-view', view)"
    />

    <slot />
  </div>
</template>

<script>
import vSearchbutton from './v-searchbutton.vue'
import vFilterbar from './v-filterbar.vue'
import vViewButtons from './v-view-buttons.vue'
import vSearchBar from './v-searchbar.vue'

export default {
  name: 'v-toolbar',
  components: {
    vSearchbutton,
    vFilterbar,
    vViewButtons,
    vSearchBar
  },

  data: () => ({
    seachbarOpen: false
  }),

  methods: {
    switchView (view) {
      const viewButtons = this.$refs['view-buttons']
      if (viewButtons) {
        viewButtons.switchView(view)
      }
    },

    openPresetModal () {
      this.$emit('open-popup')
    }
  }
}
</script>

<style lang="stylus">
.toolbar
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%

  > div
    display: grid
    grid-template-rows: 12px 1fr
    gap: 5px

  .hidden
    display: none
</style>
