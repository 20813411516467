<template>
  <div class="preset-modal" :class="classes">
    <div class="wall"></div>
    <div class="modal-wrapper">
      <img src="../assets/icons/close-purple.svg" class="close-button" @click="closePresetModal" width="23.867" height="23.867" alt="close button">
      <div class="info">
        Möchten Sie die Filter als Voreinstellung speichern?
      </div>
      <div class="button-container">
        <button
          class="save-preset"
          @click="savePreset"
          name="save-preset"
          aria-label="Save Preset"
        >
          Speichern
        </button>
        <button
          class="reset-preset"
          @click="resetPreset"
          name="reset-preset"
          aria-label="Reset Preset"
        >
          Alle Einstellungen Zurücksetzen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'v-preset',

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    open () {
      if (this.open) {
        this.increasePopupCounter()
      } else {
        this.decreasePopupCounter()
      }
    }
  },

  mounted () {
    this.$root.events.on('close-all-modals', this.closePresetModal)
  },

  computed: {
    classes () {
      return {
        open: this.open
      }
    }
  },

  methods: {
    ...mapActions([
      'increasePopupCounter',
      'decreasePopupCounter',
      'setPresetFilters',
      'unselectAllPresetFilters'
    ]),

    closePresetModal () {
      this.$emit('close-modal')
    },

    savePreset () {
      this.$root.events.emit('select-all-filters')
      this.$root.events.emit('use-filters')
      this.setPresetFilters()
      this.closePresetModal()
    },

    resetPreset () {
      this.$root.events.emit('uncheck-all-filters')
      this.$root.events.emit('use-filters')
      this.unselectAllPresetFilters()
      this.closePresetModal()
      this.$forceUpdate()
      this.$nextTick(() => {
        this.$root.events.emit('reload')
      })
    }
  }
}
</script>

<style lang="stylus">
.preset-modal
  position: fixed
  display: flex
  justify-content: center
  align-items: flex-start
  opacity: 0
  pointer-events: none
  width: 100%
  top: 0
  left: 0
  bottom: 0

  &.open
    opacity: 1
    pointer-events: all
    z-index: 5000

  .wall
    position: absolute
    background: #747474
    opacity: .6
    width: 100%
    height: 100%
    z-index: 1001

  .modal-wrapper
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: 150px
    width: 624px
    min-height: 453px
    background: #fff
    padding: 40px
    transition: .3s
    line-height: 22px
    border-radius: 12px
    z-index: 1002

    .close-button
      position: absolute
      right: 30px
      top: 25px
      cursor: pointer

    .info
      font-size: 22px
      font-family: "BMS Humanity Regular"
      margin-bottom: 25px
      line-height: 30px

    .button-container
      display: flex
      flex-direction: row
      justify-content: center
      width: 100%

      button
        height: 60px
        border-radius: 8px
        border: none
        font-family: "BMS Humanity Regular"
        font-size: 20px
        user-select: none
        cursor: pointer

      .save-preset
        width: 174px
        background: #BE2BBB
        color: #fff
        margin-right: 10px

      .reset-preset
        width: 220px
        background: #F6F3F3
        color: #595454
</style>
