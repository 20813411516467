import { createRouter, createWebHashHistory } from 'vue-router'
import vPage from '../views/v-card-overview.vue'
import vCardDetail from '../views/v-card-detail.vue'
import vUploadForm from '../views/v-upload-form.vue'

const routes = [
  {
    path: '/',
    name: 'page',
    component: vPage
  },
  {
    path: '/card-detail',
    name: 'card-detail',
    component: vCardDetail
  },
  {
    path: '/update',
    name: 'update',
    component: vUploadForm
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
