<template>
  <div class="universal-navigation" :class="classes">
    <div class="logo">
      <img src="../assets/logos/logo.svg" width="200" height="28" alt="Bristol Myers Squibb">
    </div>
    <div class="title">Study Hub</div>
    <button :class="{ disabled: hasActiveModal }" @click="goBack" name="back-reset-button" aria-label="Back Reset Button">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    hasActiveModal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        active: this.isActive
      }
    },

    isActive () {
      return this.$route.name === 'card-detail'
    },

    buttonText () {
      return this.isActive ? 'Zurück zur Übersicht' : 'App zurücksetzen/abmelden'
    }
  },

  methods: {
    ...mapActions([
      'usePresetFilters'
    ]),

    goBack (e) {
      e.preventDefault()
      e.stopPropagation()

      if (this.isActive) {
        this.$router.push('/')
      } else {
        this.usePresetFilters()
        this.$root.events.emit('use-filters')
        this.$forceUpdate()
        this.$nextTick(() => {
          this.$root.events.emit('reload')
        })
      }
    }
  }
}
</script>

<style lang="stylus">
.universal-navigation
  position: sticky
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: row
  background: #fff
  top: 0
  width: 100%
  height: 49px
  padding: 0px 40px 0px 40px
  z-index: 100000000

  .logo
    img
      width: 200px
      height: 28px

  .title
    font-size: 22px
    font-family: "BMS Humanity Light"
    margin-left: 10px
    margin-right: 10px

  button
    text-align: center
    width: 197px
    height: 49px
    background: #f6f3f3
    border: none
    cursor: pointer
    padding: 10px
    border-radius: 0px 0px 4px 4px
    color: #595454
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    transition: .3s

    &.disabled
      pointer-events: none
      opacity: 0

  &.active
    box-shadow: 0px 0px 15px 0px #cccccc

    button
      width: 197px
      height: 100%
      background: #BE2BBB
      font-size: 16px
      border-radius: 0px
      color: #FFFFFF
</style>
