<template>
  <v-universal-navigation
    v-if="render === true"
    :hasActiveModal="isIdleModalOpen"
  />
  <router-view v-if="render === true" />
  <v-idle-modal
    @close="onIdleModalClose"
    v-model:open="isIdleModalOpen"
  />
  <v-idle
    v-if="isUploadRoute === false"
    ref="idle"
    :duration="60"
    event-clearing
    @idle="openModal"
  />
</template>

<script>
import globalStyles from './styles/main.styl'
import vUniversalNavigation from './components/v-universal-navigation.vue'
import vIdleModal from './components/v-idle-modal.vue'
import vIdle from './components/v-idle.vue'
import EventBus from './eventbus'
import { mapActions } from 'vuex'

export default {
  components: {
    vUniversalNavigation,
    vIdleModal,
    vIdle
  },

  data: () => ({
    globalStyles,
    render: false,
    isIdleModalOpen: false,
    storageUrl: 'https://storage.googleapis.com/rmh-client-bms-studyhub'
  }),

  watch: {
    $route (to) {
      if (to.path === '/card-detail') {
        this.$el.parentNode.classList.add('scrollable')
      } else {
        this.$el.parentNode.classList.remove('scrollable')
      }
    }
  },

  computed: {
    isUploadRoute () {
      return this.$route?.name === 'update' ? true : false // eslint-disable-line
    }
  },

  mounted () {
    window.addEventListener('click', (e) => {
      this.$root.events.emit('global-click', e.target)
    })
  },

  async created () {
    if (process.env?.VUE_APP_BUILD_FOR_STAGE === 'true') {
      this.storageUrl = 'https://storage.googleapis.com/rmh-client-bms-studyhub-staging'
    }

    const studies = process.env.NODE_ENV === 'development'
      ? require('../mocks/studies.json')
      : await fetch(`${ this.storageUrl }/studies.json`).then(res => res.json())

    const filters = process.env.NODE_ENV === 'development'
      ? require('../mocks/filter.json')
      : await fetch(`${ this.storageUrl }/filter.json`).then(res => res.json())

    const searchTerms = process.env.NODE_ENV === 'development'
      ? require('../mocks/search.json')
      : await fetch(`${ this.storageUrl }/search.json`).then(res => res.json())

    await this.setStudies(studies)
    await this.setFilters(filters)
    await this.setSearchTerms(searchTerms)
    this.render = true

    this.$root.events = new EventBus()
    this.$root.events.on('reload', this.reload)

    this.ensurePresetFilters()
  },

  methods: {
    ...mapActions([
      'setStudies',
      'setFilters',
      'setSearchTerms',
      'setSelected',
      'setPresetFilters',
      'setMapState'
    ]),

    openModal () {
      this.isIdleModalOpen = true
      const idle = this.$refs.idle
      if (idle) {
        idle.stopTimer()
        idle.clearEvents()
      }
    },

    onIdleModalClose () {
      const idle = this.$refs.idle
      if (idle) {
        idle.initTimer()
      }
    },

    reload () {
      this.render = false
      requestAnimationFrame(() => {
        this.render = true
      })
    },

    ensurePresetFilters () {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SET_PRESET_FILTERS') {
          localStorage.setItem('presetFilters', JSON.stringify(state.presetFilters))
          localStorage.setItem('presetMapState', JSON.stringify(state.presetMapState))
        }

        if (mutation.type === 'UNSELECT_ALL_PRESET_FILTERS') {
          localStorage.removeItem('presetFilters')
          localStorage.removeItem('presetMapState')
        }
      })

      const presetFilters = localStorage.getItem('presetFilters')
      if (presetFilters) {
        this.setSelected(JSON.parse(presetFilters))
      }

      const presetMapState = localStorage.getItem('presetMapState')
      if (presetMapState) {
        this.setMapState(JSON.parse(presetMapState))
      }

      if (presetFilters && presetMapState) {
        this.setPresetFilters()
      }
    }
  },

  beforeUnmount () {
    this.$root.events.off('reload', this.reload)
  }
}
</script>

<style lang="stylus">
#app
  position: absolute
  // max-width: 1104px
  width: 100%
  min-height 100%
  max-height 100%
  height: 100%
  left: 50%
  transform: translateX(-50%)
  overflow: hidden

  &.scrollable
    overflow-x: hidden
    overflow-y: auto

  .blur
    filter: blur(5px)

  .error
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    font-size: 28px
    user-select: none

    a
      cursor: pointer
</style>
