<template>
  <div class="idle-modal" :class="classes" @click="resetScreensaver">
    <div class="wall"></div>
    <div v-if="!showScreensaver" class="modal-wrapper">
      <img
        src="../assets/icons/close-purple.svg"
        class="close-button"
        @click="closeModal"
        width="23.867"
        height="23.867"
        alt="Close"
        name="close-modal"
      >
      <div class="title">Filter zurücksetzen</div>
      <div class="info">
        Sind Sie noch da? Weil schon längere Zeit
        <br>keine Benutzereingabe mehr registriert
        <br>wurde, werden ihre Einstellungen in
        <span>
          <v-idle v-if="open" :duration="duration" has-timer display-only-seconds @idle="removeAllFiltersAndShowScreensaver"/> Sekunden
        </span>
        <br>für andere Benutzer zurückgesetzt.
      </div>
      <div class="button-container">
        <button class="stop-counter" @click="closeModal" name="close-modal" aria-label="Close Modal">
          Bitte noch nicht
        </button>
        <button class="reset-filter" @click="removeAllFilters" name="reset-filter" aria-label="Reset Button">
          Ok, jetzt zurücksetzen
        </button>
      </div>
    </div>

    <div v-else class="modal-wrapper modal-wrapper-screensaver">
      <div class="screensaver">

        <div class="title-container">
          <div class="headline">
            Hämatologie und Onkologie<br>in Deutschland
          </div>
          <div class="subheader">
            Klinische Studien
          </div>
        </div>

        <img class="logo" src="../assets/logos/logo.svg" width="200" height="28" alt="Bristol Myers Squibb">

        <div class="circles-container">
          <div class="circles">
            <div v-for="index in 4" :key="index" :class="`circle${index}`"></div>
          </div>
          <div class="button-container">
            <button class="button">Hier Klicken</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vIdle from './v-idle.vue'

export default {
  name: 'v-idle-modal',

  components: {
    vIdle
  },

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    duration: 5,
    showScreensaver: false
  }),

  watch: {
    open () {
      if (this.open) {
        this.showScreensaver = !this.isAnyFilterSet
        this.$emit('open')
        this.increasePopupCounter()
      } else {
        this.$emit('close')
        this.decreasePopupCounter()
      }
    }
  },

  computed: {
    classes () {
      return {
        open: this.open,
        'is-screensaver': this.showScreensaver
      }
    },
    ...mapGetters([
      'isAnyFilterSet'
    ])
  },

  methods: {
    ...mapActions([
      'unselectAllFilters',
      'increasePopupCounter',
      'decreasePopupCounter'
    ]),

    closeModal () {
      this.$emit('update:open', !this.open)
    },

    removeAllFilters () {
      this.unselectAllFilters()
      this.$root.events.emit('use-filters')
      this.$root.events.emit('close-all-modals')
      this.closeModal()
    },

    removeAllFiltersAndShowScreensaver () {
      this.unselectAllFilters()
      this.$root.events.emit('use-filters')
      this.$root.events.emit('close-all-modals')
      this.showScreensaver = true
    },

    resetScreensaver () {
      if (!this.showScreensaver) {
        return
      }

      this.$root.events.emit('close-all-modals')
      this.closeModal()
      this.$router.push('/')
    }
  }
}
</script>

<style lang="stylus">
.idle-modal
  position: fixed
  display: flex
  justify-content: center
  align-items: flex-start
  opacity: 0
  pointer-events: none
  width: 100%
  top: 0
  left: 0
  bottom: 0

  &.open
    opacity: 1
    pointer-events: all
    z-index: 5001

  &.is-screensaver
    cursor: pointer
    z-index: 999999999

  .wall
    position: absolute
    background: #747474
    opacity: .6
    width: 100%
    height: 100%
    z-index: 1001

  .modal-wrapper
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    margin-top: 150px
    width: 624px
    min-height: 453px
    background: #fff
    padding: 70px
    transition: .3s
    line-height: 22px
    border-radius: 12px
    z-index: 1002
    color: #595454

    &-screensaver
      width: 100%
      height: 100%
      margin-top: 0
      border-radius: 0

    .close-button
      position: absolute
      right: 30px
      top: 25px
      cursor: pointer

    .title
      font-size: 32px
      font-family: "BMS Humanity Bold"
      margin-bottom: 25px

    .info
      font-size: 22px
      font-family: "BMS Humanity Regular"
      margin-bottom: 25px
      line-height: 30px

      span
        color: #BE2BBB

        .v-idle
          display: inline-block

    .button-container
      display: flex
      flex-direction: row
      width: 100%

      button
        height: 60px
        border-radius: 8px
        border: none
        font-family: "BMS Humanity Regular"
        font-size: 20px
        user-select: none
        cursor: pointer

      .stop-counter
        width: 174px
        background: #BE2BBB
        color: #fff
        margin-right: 10px

      .reset-filter
        width: 220px
        background: #F6F3F3
        color: #595454

  .screensaver
    position: relative
    width: 100%
    height: 100%
    background-image: url("../assets/images/map.svg")
    background-repeat: no-repeat
    background-position: 95% 100%
    display: flex
    align-items: center
    justify-content: center

    .title-container
      position: absolute
      top: 0
      left: 0

      .headline
        text-transform: uppercase
        font-family: "BMS Humanity Bold"
        font-size: 52px
        line-height: 1.1
        letter-spacing: 1px

      .subheader
        margin-top: 23px
        font-family: "BMS Humanity Light"
        font-size: 72px
        letter-spacing: 1px

    .logo
      position: absolute
      bottom: 0
      left: 0

    .circles-container
      position: relative
      height: 360px
      width: 360px

      .circles
        height: 360px
        width: 360px

        > div
          animation: growAndFade 4s infinite ease-out
          background-color: #be2bbb
          border-radius: 50%
          height: 100%
          opacity: 0
          position: absolute
          width: 100%

        .circle1
          animation-delay: 2s

        .circle2
          animation-delay: 4s

        .circle3
          animation-delay: 6s

        .circle4
          animation-delay: 8s

      .button-container
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 174px
        height: 60px

        .button
          font-family: "BMS Humanity Regular"
          text-transform: uppercase
          width: 174px
          background: #be2bbb
          color: #fff
          z-index: 10

  @keyframes growAndFade
    0%
      opacity: 1
      transform: scale(0)
    100%
      opacity: 0
      transform: scale(1)
</style>
