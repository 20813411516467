<template>
  <div class="qr-code"></div>
</template>

<script>
import QRCode from '@keeex/qrcodejs-kx'

export default {
  name: 'v-qrcode-generator',
  props: {
    url: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 256
    },
    color: {
      type: String,
      required: false,
      default: '#000'
    },
    bgColor: {
      type: String,
      required: false,
      default: '#FFF'
    }
  },

  mounted () {
    this.qrCode = new QRCode(this.$el, {
      text: this.url,
      width: this.size,
      height: this.size,
      colorDark: this.color,
      colorLight: this.bgColor
    })
  }
}
</script>
