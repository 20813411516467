<template>
  <div class="v-idle">
    {{ display }}
  </div>
</template>

<script>
export default {
  name: 'v-idle',

  props: {
    /**
     * Second to wait before starting the countdown
     *
     * @constant {number}
     */
    wait: {
      type: Number,
      default: 0
    },
    /**
     * Seconds before idle detected
     *
     * @constant {number}
     */
    duration: {
      type: Number,
      default: 60 * 5
    },
    /**
     * Activity detection events
     *
     * @constant {string[]}
     */
    events: {
      type: Array,
      default: () => ['mousemove', 'keypress']
    },
    /**
     * Show timer
     *
     * @constant {Boolean}
     */
    hasTimer: {
      type: Boolean,
      default: false
    },
    /**
     * Clear on window events
     *
     * @constant {Boolean}
     */
    eventClearing: {
      type: Boolean,
      default: false
    },
    /**
     * Display only seconds
     *
     * @constant {Boolean}
     */
    displayOnlySeconds: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    display: '',
    timer: null,
    start: 0,
    counter: null,
    diff: 0,
    minutes: '',
    seconds: ''
  }),

  mounted () {
    setTimeout(() => {
      this.initTimer()
    }, this.wait * 1000)
  },

  methods: {
    initTimer () {
      this.start = Date.now()
      this.countdown()
      this.$nextTick(() => {
        this.setTimer()

        if (this.eventClearing) {
          this.events.forEach(event => {
            window.addEventListener(event, this.clearTimer)
          })
        }
      })
    },

    countdown () {
      // seconds since start
      this.diff = this.duration - parseInt(((Date.now() - this.start) / 1000))
      if (this.diff < 0) {
        return
      }

      // bitwise OR to handle parseInt
      const minute = parseInt(this.diff / 60)
      const second = parseInt(this.diff % 60)
      const pureSeconds = (minute * 60) + second

      this.minutes = minute < 10 ? `0${ minute }` : minute
      this.seconds = second < 10 ? `0${ second }` : second

      if (this.hasTimer) {
        if (this.displayOnlySeconds) {
          this.display = pureSeconds < 10 ? `0${ pureSeconds }` : pureSeconds
        } else {
          this.display = `${ this.minutes }:${ this.seconds }`
        }
      }
    },

    idle () {
      this.$emit('idle')
    },

    setTimer () {
      this.timer = window.setInterval(this.idle, this.duration * 1000)
      this.counter = window.setInterval(this.countdown, 1000)
      this.$emit('start')
    },

    stopTimer () {
      clearInterval(this.timer)
      clearInterval(this.counter)
      this.$emit('stop')
    },

    clearTimer () {
      this.stopTimer()
      this.countdown()
      this.start = Date.now()
      this.diff = 0
      this.setTimer()
    },

    clearEvents () {
      this.events.forEach(event => {
        window.removeEventListener(event, this.clearTimer)
      })
    }
  },

  beforeUnmount () {
    clearInterval(this.timer)
    clearInterval(this.counter)
    this.clearEvents()
  }
}
</script>
