<template>
  <div class="view-buttons">
    <label>Ansicht wählen</label>
    <div class="button-wrapper">
      <button class="card-view" @click="switchView('card')" :class="classes('card')" aria-label="Open Card Overview Button" name="card-view">
        <svg width="19.5" height="13.5" viewBox="0 0 19.5 13.5">
          <path id="Pfad_15360" data-name="Pfad 15360" d="M3,6H21M3,10H21M3,14H21M3,18H21" transform="translate(-2.25 -5.25)" fill="none" stroke="#595454" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </svg>
      </button>
      <button class="map-view" @click="switchView('map')" :class="classes('map')" aria-label="Open Map Button" name="map-view">
        <svg width="15.5" height="14.227" viewBox="0 0 15.5 14.227">
          <g id="Gruppe_7855" data-name="Gruppe 7855" transform="translate(-0.25 0.75)">
            <path id="Pfad_83" data-name="Pfad 83" d="M1,4.545V14.727l4.455-2.545,5.091,2.545L15,12.182V2L10.545,4.545,5.455,2Z" transform="translate(0 -2)" fill="none" stroke="#595454" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="Pfad_84" data-name="Pfad 84" d="M8,2V12.182m5.091-7.636V14.727" transform="translate(-2.545 -2)" fill="none" stroke="#595454" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'v-view-buttons',

  data: () => ({
    view: 'card'
  }),

  computed: {
    ...mapGetters([
      'selectedMapStudies',
      'selectedView'
    ])
  },

  mounted () {
    if (this.selectedMapStudies.studyIds.length > 0 || this.selectedView === 'map') {
      this.switchView('map')
    }
  },

  methods: {
    classes (view) {
      return {
        active: this.view === view
      }
    },

    switchView (view) {
      this.view = view

      this.$emit('switch-view', view)
    }
  }

}
</script>

<style lang="stylus">
.view-buttons

  label
    display: flex
    font-size: 12px
    justify-content: flex-end

  .button-wrapper

    .card-view
      background: #f6f3f3
      border-radius: 4px 0px 0px 4px
      width: 49px
      height: 49px
      border: none

    .map-view
      background: #f6f3f3
      border-radius: 0px 4px 4px 0px
      width: 49px
      height: 49px
      border: none

    button
      cursor: pointer

      &.active
        background: #BE2BBB

        svg
          path
            stroke: #FFFFFF
            -webkit-stroke: #FFFFFF
            -moz-stroke: #FFFFFF
</style>
