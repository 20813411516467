<template>
  <div class="filterbar">
    <label>Studien filtern</label>
    <div class="filterbar-wrapper">
      <div
        v-for="(filterButton, index) in filterButtons"
        :key="`filterButton-${index}`"
        :class="([filterButtonClasses(filterButton), filterButton.class])"
        @click="onButtonClick(filterButton)"
        class="filter-button"
      >
        <div>
          {{ filterButton.title }}
          <div :class="arrowClasses(filterButton)">
            <svg width="11px" height="7px" viewBox="0 0 11 7">
              <polyline stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" id="Path" stroke="#595454" points="0.467006322 0.531049615 5.5 5.53046132 10.5132058 0.531049615"></polyline>
            </svg>
          </div>
          <div
            v-if="!!getFilterCounter(filterButton)"
            class="counter-badge"
          >
            {{ getFilterCounter(filterButton) }}
          </div>
        </div>

        <v-dropdown
          v-if="filterButton.content === 'studystatus'"
          :class="filterContentClasses('studystatus')"
          @reset-filter="resetFilter('status')"
          @use-filters="useStatusFilters"
        >
          <v-checkbox
            v-for="(checkbox, index) in statusCheckboxes"
            :key="`checkbox-status-${index}`"
            :ref="`checkbox-status-${index}`"
            :checked="isStatusChecked(checkbox.value)"
            :value="checkbox.value"
            :title="checkbox.value"
            :study-status="checkbox.value"
          />
        </v-dropdown>

        <v-dropdown
          v-if="filterButton.content === 'studyphase'"
          :class="filterContentClasses('studyphase')"
          @reset-filter="resetFilter('phase')"
          @use-filters="usePhaseFilters"
        >
          <v-checkbox
            v-for="(checkbox, index) in phaseCheckboxes"
            :key="`checkbox-phase-${index}`"
            :ref="`checkbox-phase-${index}`"
            :checked="isPhaseChecked(checkbox.value)"
            :value="checkbox.value"
            :title="checkbox.value"
          />
        </v-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vDropdown from './v-dropdown.vue'
import vCheckbox from './v-checkbox.vue'

export default {
  name: 'v-filterbar',

  components: {
    vDropdown,
    vCheckbox
  },

  data: () => ({
    filterButtons: [
      {
        title: 'Indikation',
        class: 'indication',
        content: 'indication'
      },
      {
        title: 'Studienstatus',
        class: 'studystatus',
        content: 'studystatus'
      },
      {
        title: 'Studienphase',
        class: 'studyphase',
        content: 'studyphase'
      },
      {
        title: 'Wirkstoff',
        class: 'ingredient',
        content: 'ingredient'
      }
    ],
    statusCheckboxes: [],
    phaseCheckboxes: [],
    activeButtonClass: '',
    activeContent: ''
  }),

  computed: {
    ...mapGetters([
      'selectedIndications',
      'selectedIngredients',
      'selectedStatus',
      'selectedPhase',
      'filters'
    ])
  },

  mounted () {
    this.$root.events.on('close-filters', this.deactivateAllButtons)
    Object.keys(this.filters.phase).forEach(phase => {
      this.phaseCheckboxes.push({
        value: phase
      })
    })

    Object.keys(this.filters.status).forEach(status => {
      this.statusCheckboxes.push({
        value: status
      })
    })

    this.$root.events.on('uncheck-all-filters', this.resetAllFilters)
    this.$root.events.on('uncheck-status', this.unselectStatusCheckboxByValue)
    this.$root.events.on('uncheck-phase', this.unselectPhaseCheckboxByValue)
    this.$root.events.on('select-all-filters', this.useAllFilters)
  },

  methods: {
    ...mapActions([
      'selectStatus',
      'unselectStatus',
      'unselectAllStatus',
      'selectPhase',
      'unselectPhase',
      'unselectAllPhases'
    ]),

    isPhaseChecked (value) {
      return this.selectedPhase.includes(value)
    },

    isStatusChecked (value) {
      return this.selectedStatus.includes(value)
    },

    useAllFilters () {
      this.useStatusFilters()
      this.usePhaseFilters()
    },

    useStatusFilters () {
      this.unselectAllStatus()

      Object.keys(this.statusCheckboxes).forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-status-${ index }`]
        if (checkbox && checkbox.isActive) {
          this.selectStatus(checkbox.value)
        }
      })
    },

    usePhaseFilters () {
      this.unselectAllPhases()

      Object.keys(this.phaseCheckboxes).forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-phase-${ index }`]
        if (checkbox && checkbox.isActive) {
          this.selectPhase(checkbox.value)
        }
      })
    },

    async fetchAddressObject (input) {
      // Fetch address from nominatim to gather Lat Long coords for search loaction
      const result = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&country=germany&q=${ encodeURI(input) }`
      ).then(res => res.json())

      return result
    },

    onButtonClick (button) {
      this.$root.events.emit('close-indication')
      this.$root.events.emit('close-ingredient')

      if (this.activeButtonClass === button.class) {
        this.activeButtonClass = null
        this.activeContent = null
        return
      }

      this.activeButtonClass = button.class
      this.activeContent = button.content

      if (this.activeContent === 'indication') {
        this.$root.events.emit('open-indication')
      }
      if (this.activeContent === 'ingredient') {
        this.$root.events.emit('open-ingredient')
      }
    },

    deactivateAllButtons () {
      this.$root.events.emit('close-indication')
      this.$root.events.emit('close-ingredient')

      this.activeButtonClass = null
      this.activeContent = null
    },

    filterButtonClasses (button) {
      return {
        active: this.activeButtonClass === button.class,
        counter: !!this.getFilterCounter(button)
      }
    },

    getFilterCounter (button) {
      if (button) {
        if (button.title === 'Indikation') {
          return this.selectedIndications.length
        } else if (button.title === 'Wirkstoff') {
          return this.selectedIngredients.length
        } else if (button.title === 'Studienphase') {
          return this.selectedPhase.length
        } else if (button.title === 'Studienstatus') {
          return this.selectedStatus.length
        }
      }

      return 0
    },

    arrowClasses (button) {
      return ['arrow', {
        active: this.activeButtonClass === button.class,
        hide: this.getFilterCounter(button) > 0
      }]
    },

    filterContentClasses (content) {
      return {
        active: this.activeContent === content
      }
    },

    unselectStatusCheckboxByValue (value) {
      this.statusCheckboxes.forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-status-${ index }`]
        if (checkbox && checkbox.isActive && checkbox.value === value) {
          checkbox.uncheck()
        }
      })
    },

    unselectPhaseCheckboxByValue (value) {
      this.phaseCheckboxes.forEach((_, index) => {
        const [checkbox] = this.$refs[`checkbox-phase-${ index }`]
        if (checkbox && checkbox.isActive && checkbox.value === value) {
          checkbox.uncheck()
        }
      })
    },

    resetAllFilters () {
      this.resetFilter('status')
      this.resetFilter('phase')
    },

    resetFilter (type) {
      if (type === 'status') {
        this.unselectAllStatus()
        this.statusCheckboxes.forEach((_, index) => {
          const [checkbox] = this.$refs[`checkbox-status-${ index }`]
          if (checkbox) {
            checkbox.uncheck()
          }
        })
      }
      if (type === 'phase') {
        this.unselectAllPhases()
        this.phaseCheckboxes.forEach((_, index) => {
          const [checkbox] = this.$refs[`checkbox-phase-${ index }`]
          if (checkbox) {
            checkbox.uncheck()
          }
        })
      }
    }
  },

  beforeUnmount () {
    this.$root.events.off('close-filters')
    this.$root.events.off('uncheck-all-filters', this.resetAllFilters)
    this.$root.events.off('uncheck-status', this.unselectStatusCheckboxByValue)
    this.$root.events.off('uncheck-phase', this.unselectPhaseCheckboxByValue)
    this.$root.events.off('select-all-filters', this.useAllFilters)
  }
}
</script>

<style lang="stylus">
.filterbar
  flex: 1
  margin-left: 24px
  margin-right: 24px

  label
    font-size: 12px

  &-wrapper

    .filter-button
      display: inline-flex
      align-items: center
      justify-content: center
      position: relative
      border: 1px solid #e4dede
      background: white
      height: 49px
      font-family: "BMS Humanity Regular"
      font-size: 14px
      color: #595454
      padding: 0px 20px
      transition: .3s ease
      cursor: pointer
      user-select: none

      &.counter
        border: 1px solid #be2bbb
        color: #be2bbb

      .arrow
        display: inline-block
        margin-left: 5px
        transition: .3s ease
        perspective: 1000px
        transform-origin: 0% 50% 1px

        &.hide
          opacity: 0

      &.active
        background: #BE2BBB
        color: #FFFFFF

        .arrow
          transform: rotateX(180deg)

          svg

            polyline
              stroke: #FFFFFF !important
              -webkit-stroke: #FFFFFF
              -moz-stroke: #FFFFFF

      .counter-badge
        position: absolute
        display: inline-block
        min-width: 20px
        min-height: 20px
        top: 13px
        right: 13px
        border-radius: 100%
        background: #be2bbb
        margin-left: 5px
        color: #fff
        font-size: 11px
        text-align: center
        padding-top: 6px

    .indication
      border-radius: 4px 0px 0px 4px
      border-right: 1px solid transparent

    .studystatus,
    .studyphase
      border-right: 1px solid transparent

</style>
