class EventBus {
  constructor () {
    this.events = []
  }

  on (eventName, handler) {
    const event = {
      name: eventName,
      handler
    }

    if (this.events.filter(e => e === event).length === 0) {
      this.events.push(event)
    }
  }

  off (eventName, eventHandler = null) {
    if (!eventHandler) {
      this.events = this.events.filter(e => e.name !== eventName)
    } else {
      this.events = this.events.filter(e => e.name !== eventName && e.handler !== eventHandler)
    }
  }

  emit (eventName, ...values) {
    const events = this.events.filter(event => event.name === eventName)

    if (events && events.length > 0) {
      events.forEach(event => {
        if (event.handler) {
          event.handler(...values)
        } else {
          throw new Error(`no handler exist for event ${ event.name }`)
        }
      })
    }
  }
}

export default EventBus
