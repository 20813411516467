<template>
  <div class="searchbutton">
    <label>Finden</label>
    <button class="search-button" aria-label="Search Button" @click="openSearchBar" :class="classes" name="search-button">
      <svg width="19.811" height="19.811" viewBox="0 0 19.811 19.811">
        <g id="Gruppe_5407" data-name="Gruppe 5407" transform="translate(-2.25 -2.25)">
          <path id="Pfad_91" data-name="Pfad 91" d="M10.5,18A7.5,7.5,0,1,0,3,10.5,7.5,7.5,0,0,0,10.5,18Z" fill="none" stroke="#595454" stroke-width="1.5"/>
          <path id="Pfad_92" data-name="Pfad 92" d="M21,21l-5-5" fill="none" stroke="#595454" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'v-searchbutton',

  computed: {
    ...mapGetters([
      'selectedSearchTerms'
    ]),

    classes () {
      return {
        active: this.selectedSearchTerms.length > 0
      }
    }
  },

  methods: {
    openSearchBar () {
      this.$emit('open-searchbar')
      this.$root.events.emit('close-filters')
    }
  }
}
</script>

<style lang="stylus">
.searchbutton

  label
    font-size: 12px

  .search-button
    display: flex
    align-items: center
    width: 49px
    height: 49px
    background: white
    box-sizing: border-box
    padding: 13px
    border: 1px solid #e4dede
    border-radius: 4px

    &.active
      border-color: #BE2BBB

      svg
        path
          stroke: #BE2BBB
</style>
