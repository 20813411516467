<template>
  <div class="checkbox" @click="toggle">
    <div class="check-button" :class="classes">
      <svg width="14.827" height="11.859" viewBox="0 0 14.827 11.859">
        <path id="Pfad_75" data-name="Pfad 75" d="M4,11.25,7.75,15,16,6" transform="translate(-2.586 -4.587)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      </svg>
    </div>
    <label>{{ title }}</label>
  </div>
</template>

<script>
export default {
  name: 'v-checkbox',

  props: {
    checked: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    studyStatus: {
      type: String,
      default: null
    }
  },

  data: () => ({
    isActive: false
  }),

  computed: {
    classes () {
      return {
        active: this.isActive === true,
        recruiting: this.studyStatus === 'Recruiting' && this.isActive === true,
        'not-recruiting': this.studyStatus === 'Not Yet Recruiting' && this.isActive === true,
        'active-not': this.studyStatus === 'Active, not recruiting' && this.isActive === true,
        completed: this.studyStatus === 'Completed' && this.isActive === true
      }
    }
  },

  mounted () {
    this.isActive = this.checked
  },

  methods: {
    toggle () {
      this.isActive = !this.isActive
      this.$emit('check', {
        active: this.isActive,
        value: this.value
      })
    },

    uncheck () {
      this.isActive = false
      this.$emit('check', {
        active: this.isActive,
        value: this.value
      })
    }
  }
}
</script>

<style lang="stylus">
.checkbox
  display: flex
  flex-direction: row
  align-items: center

  .check-button
    display: inline-flex
    justify-content: center
    align-items: center
    width: 42px
    min-width: 42px
    max-width: 42px
    min-height: 42px
    max-height: 42px
    height: 42px
    background: #e5e1e0
    border-radius: 8px 0px 0px 8px
    border: none
    margin-right: 10px
    cursor: pointer

    &.active
      background: #BE2BBB

    &.recruiting
      background: #01c370

    &.not-recruiting
      background: #33d6f1

    &.active-not
      background: #ffba4a

    &.completed
      background: #a69f9f

  label
    font-size: 14px
    color: #595454
    user-select: none
</style>
